import { flagRoot } from "../../utils";

export interface State {
    /**
     * User id
     */
    id: number;
    /**
     * User email
     */
    email: string;
    /**
     * User first name
     */
    firstName: string;
    /**
     * User last name
     */
    lastName: string;
    /**
     * People ID
     */
    peopleID: string;
    /**
     * User role
     */
    role: "admin" | "office_worker";
    /**
     * Auth token
     */
    token?: string;

    refreshToken?: string;
    /** 
    * Logo Id
    */
    logoId?: string | null;
}

export function defaults(): State {
    const s: State = {
        email: "",
        id: 0,
        firstName: "",
        peopleID: "",
        lastName: "",
        token: "",
        role: "office_worker",
        refreshToken: "",
        logoId: "",
    };
    return flagRoot(s);
}
