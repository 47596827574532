import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const getRestBaseEndpoint = () => {
    //getDeployOptions() doesnt seem to be working
    const options = (window as any).__DEPLOY_OPTIONS;
    return options ? options.restApiUrl : "";
};

const axiosInstance: AxiosInstance = axios.create({
    baseURL: getRestBaseEndpoint(),
});

const attachTokenToRequest = (config: AxiosRequestConfig) => {
    let localStorageVal = localStorage.getItem("quipa-store");
    const parsedVal = localStorageVal ? JSON.parse(localStorageVal) : null;

    if (parsedVal && parsedVal.user) {
        // NOSONAR
        //current typescript version doesnt support optional chaining, hence NOSONAR above
        const { token } = parsedVal.user;
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error &&
            error.response &&
            (error.response.status === 403 || error.response.status === 401) &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            let store: any = localStorage.getItem("quipa-store");
            let refreshToken;
            if (store !== null) {
                store = JSON.parse(store);
                refreshToken = store.user.refreshToken;
            }
            const { data } = await axiosInstance.post("/refresh-token", {
                refreshToken,
            });
            localStorage.setItem(
                "quipa-store",
                JSON.stringify({
                    ...store,
                    user: { ...store.user, token: data.accessToken },
                })
            );

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(attachTokenToRequest);

export default axiosInstance;
