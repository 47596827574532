import { Api, Req } from "@quipa/api";

/**
 * Login by username
 * @param username
 * @param password
 */
export const loginByEmail = (email: string, password: string, remember: boolean) =>
    Api.createAction(Api.LOGIN, { email, password }, { isOptimistic: false, authenticated: false, remember });

/**
 * Login by token
 * @param token
 */
export const loginByToken = (token: string) =>
    Api.createAction(Api.LOGIN, { token }, { isOptimistic: false, authenticated: false });

/**
 * Signup
 * @param params user params
 */
export const signup = (params: Api.SignupAction[Req]) =>
    Api.createAction(Api.SIGNUP, params, { isOptimistic: false, authenticated: false });

/**
 * Check email uniqueness
 * @param email
 */
export const validateEmail = (email: string) =>
    Api.createAction(Api.VALIDATE_EMAIL, { email }, { isOptimistic: false, authenticated: false });

/**
 * Logout current user
 */
export const logout = () => Api.createAction(Api.LOGOUT, {}, { authenticated: true, simpleFlow: true });

/**
 * Request password reset email
 * @param email
 */
export const requestPasswordReset = (email: string) => Api.createAction(Api.REQUEST_PASSWORD_RESET, { email }, { authenticated: false });

/**
 * Reset password for user
 * @param code
 * @param password
 */
export const passwordReset = (code: string, password: string) => Api.createAction(Api.PASSWORD_RESET, { password, code }, { authenticated: false });

/**
 * Update user info
 * @param email
 * @param name
 * @param username
 */
export const updateUser = (change: Api.UpdateUserAction[Req]) => Api.createAction(Api.UPDATE_USER, change, { isOptimistic: true });

/**
 * Update user password
 */
export const updateUserPassword = (oldPassword: string, password: string) =>
    Api.createAction(Api.UPDATE_USER_PASSWORD, { oldPassword, password }, { isOptimistic: false });

/**
 * Send feedback email
 * @param text
 */
export const sendFeedback = (text: string, accountId: number) =>
    Api.createAction(Api.SEND_FEEDBACK, { content: text, accountId }, { isOptimistic: false, authenticated: false });

export const refreshToken = (refreshToken: string) =>
    Api.createAction(Api.REFRESH_TOKEN, { refreshToken }, { isOptimistic: false, authenticated: false });

/**
 * Upload image
 * @param accountId
 * @param file
 */
export const uploadProfileImage = (accountId: number, file: File) =>
    Api.createAction(
        Api.UPLOAD_PROFILE_IMAGE,
        { accountId, data: file },
        { authenticated: true, isOptimistic: false }
    );
