import {
    Button,
    FormField,
    ValidationForm,
    ValidationPopupStateInput,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AuthHeader } from "../components/auth/auth_header";
import { Logo } from "../components/auth/logo";
import { RouteLink } from "../components/navigation/route_link";
// import { UserModule } from "../redux";
import styled from "../theme";
import { requestPasswordReset } from "../api/password-reset";

// tslint:disable-next-line:no-empty-interface
export interface RequestResetPasswordProps extends RouteComponentProps {}

const SigninLink = styled(RouteLink)`
    font-size: 0.8em;
`;

export class RequestResetPassword extends PureComponent<
    RequestResetPasswordProps & DispatchProp
> {
    public render(): JSX.Element {
        return (
            <>
                <Logo />
                <AuthHeader uiSize="1em">
                    Reset Password
                    <SigninLink to="/login">Back to Login</SigninLink>
                </AuthHeader>
                <ValidationForm
                    uiOnSubmit={this.submit}
                    uiSubmitFailedIcon
                    uiReportValidityMode="firstInvalid"
                    uiDimmerBlurring={false}
                    uiUseSubmitFailedMessageFromError
                >
                    <FormField uiRequired>
                        <ValidationPopupStateInput
                            id="email"
                            name="email"
                            tabIndex={1}
                            required
                            minLength={1}
                            maxLength={255}
                            type="email"
                            placeholder="Your E-Mail"
                            uiPopupPosition="bottom right"
                        />
                    </FormField>
                    <Button uiColor="primary" type="submit" tabIndex={2}>
                        Reset
                    </Button>
                </ValidationForm>
            </>
        );
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        const {
            //  dispatch,
            history,
        } = this.props;
        // tslint:disable-next-line:no-string-literal
        const email = (
            e.currentTarget.elements["email"] as HTMLInputElement
        ).value.trim();

        const res = await requestPasswordReset(email);

        // const res = await dispatch(UserModule.requestPasswordReset(email));
        if (res && res.error) {
            // tslint:disable-next-line:no-string-literal
            if (
                res.message === "A user account with that email does not exist"
            ) {
                console.log("eeeeeee");

                // we can use error message from the server too, but it's a bit long?
                throw new Error("Email not found");
            } else {
                throw new Error(
                    "Unable to submit form. Please contact the support"
                );
            }
        }

        console.log("push");

        history.push("/password_reset");
    }
}

export const RequestResetPasswordContainer = connect()(RequestResetPassword);
