import Bind from "lodash-decorators/bind";
import React, { PureComponent } from "react";
import styled from "../../../../theme";
import ColorMenuButton from "./color_picker";
import LineWeightMenu from "./line_weight_menu";
import PencilMenuButton from "./pencil_menu";

import { RGBColor } from "react-color";
import ClearIconE from "../../../../assets/icons/drawing/clear_trash.svg";
import ColorIconE from "../../../../assets/icons/drawing/color_fill_tool.svg";
import LineWeightIconE from "../../../../assets/icons/drawing/line_weight_tool.svg";
import PencilIconE from "../../../../assets/icons/drawing/pencil_tool.svg";
import SelectionIconE from "../../../../assets/icons/drawing/selection_box.svg";
import TextIconE from "../../../../assets/icons/drawing/text_tool.svg";

const TextIcon = styled(TextIconE)`
    padding: 5px;
    width: 100%;
    height: 100%;
`;

const PencilIcon = styled(PencilIconE)`
    padding-top: 5px;
    width: 100%;
    height: 100%;
`;

const LineWeightIcon = styled(LineWeightIconE)`
    width: 100%;
    height: 100%;
`;

const SelectionIcon = styled(SelectionIconE)`
    padding: 5px;
    width: 100%;
    height: 100%;
`;

const ColorIcon = styled(ColorIconE) <ColorProps>`
    padding: 2px;
    stroke: ${props => `rgba(${props.strokeColor.r}, ${props.strokeColor.g}, ${props.strokeColor.b}, ${props.strokeColor.a})`};
    fill: ${props => `rgba(${props.filledColor.r}, ${props.filledColor.g}, ${props.filledColor.b}, ${props.filledColor.a})`};
    stroke-width: 3;
    width: 100%;
    height: 100%;
`;

const ClearIcon = styled(ClearIconE)`
    padding: 5px;
    width: 100%;
    height: 100%;
`;

export interface MenuButtonProps {
    uiWidth: number;
}

export const MenuButton = styled.div<MenuButtonProps>`
    width: ${props => props.uiWidth ? `${props.uiWidth}px` : `6vh`};
    height: ${props => props.uiWidth ? `${props.uiWidth}px` : `6vh`};
    margin: ${props => props.uiWidth ? `${props.uiWidth / 30}px` : `0.2vh`};
    padding: 0;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #306f82;
    cursor: pointer;
    z-index: 3;
`;

const MenuButtonText = styled.div<MenuButtonProps>`
    
    margin: ${props => props.uiWidth ? `${props.uiWidth / 60}px 0` : `0.1vh 0`};
    font-size: ${props => props.uiWidth ? props.uiWidth * 0.35 : 15}px;
`;

const MenuButtonContainer = styled.div<MenuButtonProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: ${props => props.uiWidth ? props.uiWidth * 1.4 : 60}px;
`;

const Container = styled.div`
    display: flex;

    &.vertical{
        flex-direction: column;
        align-self: center;
    }
`;

interface ColorProps {
    filledColor: RGBColor;
    strokeColor: RGBColor;
}

interface Props {
    uiWidth: number;
    tool: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle";
    arrow: boolean;
    lineWeight: number;
    lineType: "solid" | "dashed";
    filledColor: RGBColor;
    strokeColor: RGBColor;
    fontSize: number;
    selected: boolean;
    handleToolbar(type: "line" | "pen" | "arrow" | "text" | "selection" | "rect" | "circle"): void;
    handleColor(filledColor: RGBColor, strokeColor: RGBColor): void;
    handleLineWeight(weight: number, lineType: "solid" | "dashed"): void;
    handleClear(): void;
    orderType?: string;
}

class Toolbar extends PureComponent<Props> {
    public render() {
        const width = Math.min(this.props.uiWidth / 10, 50);
        return (
            <Container className={this.props.orderType === 'custom-order' ? 'vertical' : ''}>
                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>TEXT</MenuButtonText>
                    <MenuButton uiWidth={width} title="" onClick={this.handleText}>
                        <TextIcon />
                    </MenuButton>
                </MenuButtonContainer>

                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>PENCIL</MenuButtonText>
                    <PencilMenuButton uiWidth={width} selected={this.props.selected} onClicked={this.props.handleToolbar}>
                        <PencilIcon />
                    </PencilMenuButton>
                </MenuButtonContainer>

                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>WEIGHT</MenuButtonText>
                    <LineWeightMenu uiWidth={width} onClicked={this.props.handleLineWeight}>
                        <LineWeightIcon />
                    </LineWeightMenu>
                </MenuButtonContainer>

                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>SELECT</MenuButtonText>
                    <MenuButton uiWidth={width} title="" onClick={this.handleSelection}>
                        <SelectionIcon />
                    </MenuButton>
                </MenuButtonContainer>

                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>COLOR</MenuButtonText>
                    <ColorMenuButton
                        uiWidth={width}
                        filledColor={this.props.filledColor}
                        strokeColor={this.props.strokeColor}
                        onColorChanged={this.props.handleColor}
                    >
                        <ColorIcon filledColor={this.props.filledColor} strokeColor={this.props.strokeColor} />
                    </ColorMenuButton>
                </MenuButtonContainer>

                <MenuButtonContainer uiWidth={width}>
                    <MenuButtonText uiWidth={width}>CLEAR</MenuButtonText>
                    <MenuButton uiWidth={width} title="" onClick={this.props.handleClear}>
                        <ClearIcon />
                    </MenuButton>
                </MenuButtonContainer>
            </Container>
        );
    }

    @Bind()
    private handleText() {
        this.props.handleToolbar("text");
    }

    @Bind()
    private handleSelection() {
        this.props.handleToolbar("selection");
    }
}

export default Toolbar;
