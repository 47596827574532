import { getDeployOptions } from "./environment";

/**
 * Application configuration wrapper
 */
export default class Config {
    /**
     * @return
     */
    public static realtimeUrl(prefix: string = ""): string {
        const OPTIONS = getDeployOptions();
        const schemeType = OPTIONS.apiScheme;
        const realtimeUrl = OPTIONS.apiUrl;
        return `${
            schemeType ? schemeType + "://" : "//"
        }${prefix}${realtimeUrl}`;
    }

    /**
     * Return stripe key
     */
    public static get stripeKey(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.stripeKey;
    }

    public static get quickbookClientId(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.quickbookClientId;
    }

    public static get quickbookRedirectUri(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.quickbookRedirectUri;
    }

    public static get fileStoreUrl(): string {
        const OPTIONS = getDeployOptions();
        return OPTIONS.fileStoreUrl;
    }
}
