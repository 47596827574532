import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import {
    FormFields,
    FormFieldsAction,
    formFieldsInitialState,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import {
    FormInput,
    FormLabel,
    RadioInput,
    RadioLabel,
    RightCellWithBorder,
    TooltipPopup,
} from "../styles";
import React, { ChangeEvent, Dispatch, FC, useEffect, useState } from "react";
import { formatPrice, formatSectionTotalPrice } from "../helpers";
import { ValidationErrorInfo } from "../../calculator/interfaces-common";
import { Checkbox } from "@ramble/ramble-ui";
import { FabricPatternItem, highlightStyle } from "../../calculator/calculator";
import { Client } from "packages/frontend/src/redux/modules/client";

interface StandardItemModalPricingProps {
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    formFields: FormFields;
    dispatch: Dispatch<FormFieldsAction>;
    highlightedSection?: string | null;

    indexOfMainFabric?: number;
    updateStateByName?(
        name: string,
        value: string | number | boolean | number[] | undefined | object,
        isSaving?: boolean
    ): void;
    clearHighlightSection?(name: string): void;
    highLightSection?(name: string): void;
    client?: Client;
}

enum PricingSection {
    Retail = "Retail",
    Trade = "Trade",
}

const validateRetailClient = (mainFabric: FabricPatternItem): string[] => {
    let name = [];
    if (!mainFabric.retailPricingMethod || !mainFabric.retailPricingValue) {
        name.push("Retail");
    } else if (
        mainFabric.retailPricingMethod === "markUpCostRetail" &&
        !mainFabric.cost
    ) {
    name.push("Item Cost");
    } else if (
        mainFabric.retailPricingMethod === "discountMsrpRetail" &&
        !mainFabric.msrp
    ) {
        name.push("Item MSRP");
    }
    return name;
};

const validateTradeClient = (mainFabric: FabricPatternItem): string[] => {
    let name = [];
    if (!mainFabric.tradePricingMethod || !mainFabric.tradePricingValue) {
        name.push("Trade");
    } else if (
        mainFabric.tradePricingMethod === "markUpCostTrade" &&
        !mainFabric.cost
    ) {
        name.push("Item Cost");
    } else if (
        mainFabric.tradePricingMethod === "discountMsrpTrade" &&
        !mainFabric.msrp
    ) {
        name.push("Item MSRP");
    }
    return name;
};

export const validateMyShopItemPricing = (
    tabIndex: number,
    mainFabric: FabricPatternItem,
    client: Client
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    let name = [];

    const billingType = client.billToParent
        ? client.billingDetails.billingType
        : client.billingDetailsDirectly
        ? client.billingDetailsDirectly.billingType
        : null;
    switch (billingType) {
        case PricingSection.Retail:
            name = validateRetailClient(mainFabric);
            break;
        case PricingSection.Trade:
            name = validateTradeClient(mainFabric);
            break;
        default:
            name = validateRetailClient(mainFabric).concat(
                validateTradeClient(mainFabric)
            );
            break;
    }
    if (name.length > 0) {
        errors.push({
            tabIndex,
            name,
            message: `${mainFabric.name} pricing is required if workroom is ordering.`,
            itemClassId: mainFabric.itemClassId,
            itemId: mainFabric.id,
        });
    }

    return errors;
};
const StandardItemModalPricing: FC<StandardItemModalPricingProps> = ({
    browser,
    formFields,
    dispatch,
    highlightedSection,
    updateStateByName,
    indexOfMainFabric,
    clearHighlightSection,
    highLightSection,
    client,
}) => {
    const isItemTypeService = formFields.itemTypeId === 2;
    const [isShippingCostChecked, setIsShippingCostChecked] =
        useState<boolean>(false);

    const [isHandlingFeeChecked, setIsHandlingFeeChecked] =
        useState<boolean>(false);
    const [isRadioChecked, setIsRadioChecked] = useState(false);

    const formInputStyleClass = "px-1.5 py-2 ml-2 max-w-[80px]";

    const getIsMsrpRequired = () => {
        if (
            client &&
            client.billingType &&
            (formFields.retailPricingValue || formFields.tradePricingValue)
        ) {
            return ["discountMsrpRetail", "discountMsrpTrade"].includes(
                client && client.billingType === PricingSection.Retail
                    ? formFields.retailPricingMethod
                    : formFields.tradePricingMethod
            );
        } else if (
            !client &&
            (formFields.retailPricingValue || formFields.tradePricingValue)
        ) {
            return (
                ["discountMsrpRetail", "discountMsrpTrade"].includes(
                    formFields.tradePricingMethod
                ) ||
                ["discountMsrpRetail", "discountMsrpTrade"].includes(
                    formFields.retailPricingMethod
                )
            );
        }
    };
    const getIsCostRequired = () => {
        if (
            client &&
            client.billingType &&
            (formFields.retailPricingValue || formFields.tradePricingValue) &&
            (["markUpCostRetail", "markUpCostTrade"].includes(
                formFields.retailPricingMethod
            ) ||
                ["markUpCostRetail", "markUpCostTrade"].includes(
                    formFields.tradePricingMethod
                ))
        ) {
            return ["markUpCostRetail", "markUpCostTrade"].includes(
                client && client.billingType === PricingSection.Retail
                    ? formFields.retailPricingMethod
                    : formFields.tradePricingMethod
            );
        } else if (
            !client &&
            (formFields.retailPricingValue || formFields.tradePricingValue) &&
            (["markUpCostRetail", "markUpCostTrade"].includes(
                formFields.retailPricingMethod
            ) ||
                ["markUpCostRetail", "markUpCostTrade"].includes(
                    formFields.tradePricingMethod
                ))
        ) {
            return (
                ["markUpCostRetail", "markUpCostTrade"].includes(
                    formFields.retailPricingMethod
                ) ||
                ["markUpCostRetail", "markUpCostTrade"].includes(
                    formFields.tradePricingMethod
                )
            );
        }
        if (formFields.handlingFeeMethod === "markUpProductCost") {
            return ["markUpProductCost"].includes(formFields.handlingFeeMethod);
        }
        if (
            !client &&
            (formFields.retailPricingMethod || formFields.tradePricingMethod) &&
            (["costPlusRetail", "costPlusTrade"].includes(
                formFields.retailPricingMethod
            ) ||
                ["costPlusRetail", "costPlusTrade"].includes(
                    formFields.tradePricingMethod
                ))
        ) {
            return (
                ["costPlusRetail", "costPlusTrade"].includes(
                    formFields.retailPricingMethod
                ) ||
                ["costPlusRetail", "costPlusTrade"].includes(
                    formFields.tradePricingMethod
                )
            );
        }
    };
    const getIsShippingCostActive = (): boolean => {
        //for typescript
        return isShippingCostChecked || !!formFields.shippingCost;
    };

    const getIsHandlingFeeActive = (): boolean => {
        return isHandlingFeeChecked || !!formFields.handlingFeeMethod;
    };

    const isShippingCostActive = getIsShippingCostActive();
    const isHandlingFeeActive = getIsHandlingFeeActive();

    const toggleShippingCost = () => {
        setIsShippingCostChecked(!isShippingCostChecked);
    };
    let form = document.getElementById("validation-form") as HTMLInputElement;
    const toggleHandlingFee = () => {
        setIsHandlingFeeChecked(!isHandlingFeeChecked);
        setIsRadioChecked(false);
    };

    const validateTradeClient = (formFields: FormFields) => {
        if (formFields.tradePricingMethod) {
            if (clearHighlightSection) {
                clearHighlightSection("Trade");
            }
        } else if (
            JSON.stringify(formFields) !==
                JSON.stringify(formFieldsInitialState) &&
            (!formFields.tradePricingValue || !formFields.tradePricingMethod)
        ) {
            if (highLightSection) {
                highLightSection("Trade");
            }
        }

        if (form && form.checkValidity() === false) {
            form.reportValidity();
        }
    };

    const validateRetailClient = (formFields: FormFields) => {
        if (formFields.retailPricingMethod) {
            if (clearHighlightSection) {
                clearHighlightSection("Retail");
            }
        } else if (
            JSON.stringify(formFields) !==
                JSON.stringify(formFieldsInitialState) &&
            (!formFields.retailPricingValue || !formFields.retailPricingMethod)
        ) {
            if (highLightSection) {
                highLightSection("Retail");
            }
        }

        if (form && form.checkValidity() === false) {
            form.reportValidity();
        }
    };

    const markUpShippingCostRequired = (): boolean | undefined => {
        if (formFields.handlingFeeMethod) {
            return ["markUpShippingCost"].includes(
                formFields.handlingFeeMethod
            );
        }
    };
    function checkShippingCost() {
        if (formFields.shippingCost){
            return !!formFields.shippingCost;
        }
    };

    useEffect(() => {
        const isChecked = checkShippingCost();  
        if(isChecked){
            setIsShippingCostChecked(isChecked);
        }
    }, [formFields.shippingCost]);

    function checkHandlingCost() {
        if (formFields.handlingFeeMethod){
            return !!formFields.handlingFeeMethod;
        }
    };

    useEffect(() => {
        const isChecked = checkHandlingCost();  
        if(isChecked){
            setIsHandlingFeeChecked(isChecked);
        }
    }, [formFields.shippingCost]);

    useEffect(() => {
        if (client && client.billToParent && client.billingDetails.billingType === PricingSection.Trade) {
            validateTradeClient(formFields);
        } else if(client && !client.billToParent && client.billingDetailsDirectly && client.billingDetailsDirectly.billingType === PricingSection.Trade){
            validateTradeClient(formFields);
        }
        if (client && client.billToParent && client.billingDetails.billingType === PricingSection.Retail) {
            validateRetailClient(formFields);
        } else if(client && !client.billToParent && client.billingDetailsDirectly && client.billingDetailsDirectly.billingType === PricingSection.Retail){
            validateRetailClient(formFields)
        }
    }, [formFields, client]);

    const handlePricingMethodRadioChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        keyParam?: string
    ) => {
        const pricingMethodKey = keyParam || e.currentTarget.id;
        const pricingMethodValue: string | number = e.currentTarget.value;

        const relatedPricingValueKey = pricingMethodKey.includes(
            PricingSection.Retail.toLowerCase()
        )
            ? "retailPricingValue"
            : "tradePricingValue";

        const updatedForm = {
            [pricingMethodKey]: pricingMethodValue,
            [relatedPricingValueKey]: "",
        };

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const removePricingMethod = (
        e: React.MouseEvent<HTMLInputElement>,
        keyParam?: string,
        isChecked?: boolean
    ) => {
        const pricingMethodKey = keyParam || e.currentTarget.id;
        const pricingMethodValue: string | number = e.currentTarget.value;

        const relatedPricingValueKey = pricingMethodKey.includes(
            PricingSection.Retail.toLowerCase()
        )
            ? "retailPricingValue"
            : "tradePricingValue";

        let updatedForm = {};

        if (isChecked) {
            updatedForm = {
                [pricingMethodKey]: false,
                [relatedPricingValueKey]: "",
            };
        } else {
            updatedForm = {
                [pricingMethodKey]: pricingMethodValue,
                [relatedPricingValueKey]: "",
            };
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const handleHandleFeeRadioChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        keyParam: string,
        relatedValueKey: string
    ) => {
        const updatedForm = {
            [keyParam]: e.currentTarget.value,
            [relatedValueKey]: "",
        };
        setIsRadioChecked(!isRadioChecked);
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: updatedForm,
        });
    };

    const handlePricingInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        keyParam?: string,
        type: string = "dollarAmount"
    ) => {
        let value = e.target.value;

        const integerPartMaxLength = 5;
        const decimalPartMaxLength = 2;

        if (value.startsWith("0") && value.length > 1) {
            value = "0." + value.slice(1);
        }

        value = value.replace(/[^\d.]/g, "");

        value = value.replace(/\.{2,}/g, ".");

        if (value.startsWith(".")) {
            value = "0" + value;
        }

        value = value.replace(/^0+(?=\d)/, "");

        const dotIndex = value.indexOf(".");
        if (dotIndex !== -1) {
            value =
                value.slice(0, dotIndex + 1) +
                value.slice(dotIndex + 1).replace(/\./g, "");
        }

        const decimalParts = value.split(".");
        if (decimalParts.length > 1) {
            value = `${decimalParts[0]}.${decimalParts[1].slice(
                0,
                decimalPartMaxLength
            )}`;
        }

        const integerPart = value.split(".")[0];
        if (integerPart.length > integerPartMaxLength) {
            value = integerPart.slice(0, integerPartMaxLength);
        }

        const keyboardEvent = e.nativeEvent as any;
        const isBackspaceAction =
            keyboardEvent.inputType === "deleteContentForward";

        if (
            isBackspaceAction &&
            value === "0" &&
            e.currentTarget.value === "0"
        ) {
            value = "";
        }

        dispatch({
            type: "updateFormFields",
            formFieldUpdates: {
                [keyParam || type]: value,
            },
        });
    };

    const getPricingSectionPrice = (pricingSection: string) => {
        const pricingSectionLower = pricingSection.toLowerCase();

        const pricingMethodTextKey = `${pricingSectionLower}PricingMethod`;
        const pricingMethodValueKey = `${pricingSectionLower}PricingValue`;

        const pricingMethodText = formFields[pricingMethodTextKey];
        let pricingMethodValue = formFields[pricingMethodValueKey];

        let msrp = formFields.msrp;
        let cost = formFields.cost;

        if (
            ["discountMsrpRetail", "discountMsrpTrade"].includes(
                pricingMethodText
            )
        ) {
            if (msrp && pricingMethodValue) {
                msrp = +msrp;
                pricingMethodValue = +pricingMethodValue;
                const discountedVal = (pricingMethodValue / 100) * msrp;
                return formatSectionTotalPrice(msrp - discountedVal);
            } else {
                return "TBD";
            }
        }

        if (
            ["markUpCostRetail", "markUpCostTrade"].includes(pricingMethodText)
        ) {
            if (cost && pricingMethodValue) {
                cost = +cost;
                pricingMethodValue = +pricingMethodValue;
                const markUpVal = (pricingMethodValue / 100) * cost;
                return formatSectionTotalPrice(cost + markUpVal);
            } else {
                return "TBD";
            }
        }

        if (["costPlusRetail", "costPlusTrade"].includes(pricingMethodText)) {
            if (cost && pricingMethodValue) {
                cost = +cost;
                pricingMethodValue = +pricingMethodValue;
                return formatSectionTotalPrice(cost + pricingMethodValue);
            } else {
                return "TBD";
            }
        }
        return pricingMethodValue ? `$${pricingMethodValue}` : "TBD";
    };
    const getPricingMethodSection = (pricingSection: PricingSection) => {
        const radioGroupName = `${pricingSection}Radio`;
        const pricingMethodKey = `${pricingSection.toLowerCase()}PricingMethod`; //Eg: retailPricingMethod => db field name
        const pricingValueKey = `${pricingSection.toLowerCase()}PricingValue`; //Eg: tradePricingValue => db field name

        const markUpCost = `markUpCost${pricingSection}`;
        const discountMsrp = `discountMsrp${pricingSection}`;
        const rate = `rate${pricingSection}`;
        const costPlus = `costPlus${pricingSection}`;

        const isMarkUpChecked = formFields[pricingMethodKey] === markUpCost; //Eg: formFields["retailPricingMethod"]="markUpCostRetail"
        const isDiscountMsrpChecked =
            formFields[pricingMethodKey] === discountMsrp; //Eg: formFields["tradePricingMethod"]="discountMsrpTrade"
        const isRateChecked = formFields[pricingMethodKey] === rate;
        const isCostPlusChecked = formFields[pricingMethodKey] === costPlus;

        //if corresponding radios are selected, then keep the text value, else clear it
        const markUpValue = isMarkUpChecked ? formFields[pricingValueKey] : "";
        const discountMsrpValue = isDiscountMsrpChecked
            ? formFields[pricingValueKey]
            : "";
        const rateValue = isRateChecked ? formFields[pricingValueKey] : "";
        const costPlusValue = isCostPlusChecked
            ? formFields[pricingValueKey]
            : "";

        return (
            <>
                <div
                    id={pricingSection}
                    style={
                        highlightedSection &&
                        highlightedSection.indexOf(pricingSection) >= 0
                            ? { ...highlightStyle, padding: "10px" }
                            : {}
                    }
                >
                    <span style={{ fontWeight: "600" }}>{pricingSection}</span>
                    <div className="flex flex-col mt-4 mb-2">
                        <div className="flex flex-row items-center mb-2">
                            <RadioLabel className="mb-2">
                                <RadioInput
                                    id={`${markUpCost}Radio`}
                                    name={radioGroupName}
                                    type="radio"
                                    checked={isMarkUpChecked}
                                    value={markUpCost}
                                    onClick={(e) => {
                                        removePricingMethod(
                                            e,
                                            pricingMethodKey,
                                            isMarkUpChecked
                                        );
                                    }}
                                    onChange={(e) =>
                                        handlePricingMethodRadioChange(
                                            e,
                                            pricingMethodKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                />
                                Mark-up cost
                                <div className="flex flex-row items-center">
                                    <FormInput
                                        id={`${markUpCost}Input`}
                                        name={`Markup Cost ${pricingSection} Input`}
                                        className={formInputStyleClass}
                                        type="text"
                                        value={markUpValue}
                                        onChange={(e) =>
                                            handlePricingInputChange(
                                                e,
                                                pricingValueKey
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={!isMarkUpChecked}
                                        required={
                                            !!isMarkUpChecked
                                            // ||
                                            // (client &&
                                            //     client.billingType ===
                                            //         pricingSection)
                                        }
                                        uiDisabled={false}
                                        autoComplete="off"
                                        list="autocompleteOff"
                                        pattern="^(0|[1-9]\d*)(\.\d+)?$"
                                        uiValidityMessages={{
                                            patternMismatch:
                                                "Mark-up Cost % is required if selected",
                                        }}
                                    />
                                    <span>%</span>
                                </div>
                            </RadioLabel>
                        </div>
                        {isItemTypeService ? (
                            <div className="flex flex-row items-center mb-2">
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id={`${costPlus}Radio`}
                                        name={radioGroupName}
                                        type="radio"
                                        checked={isCostPlusChecked}
                                        onClick={(e) => {
                                            removePricingMethod(
                                                e,
                                                pricingMethodKey,
                                                isCostPlusChecked
                                            );
                                        }}
                                        value={costPlus}
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Cost+
                                    <div className="flex flex-row items-center ml-2">
                                        <FormLabel htmlFor={`${costPlus}Input`}>
                                            $
                                        </FormLabel>
                                        <FormInput
                                            id={`${costPlus}Input`}
                                            name={`Cost Plus ${pricingSection} Input`}
                                            className={formInputStyleClass}
                                            type="text"
                                            value={costPlusValue}
                                            onChange={(e) =>
                                                handlePricingInputChange(
                                                    e,
                                                    pricingValueKey
                                                )
                                            }
                                            uiReportOnBlur={false}
                                            disabled={!isCostPlusChecked}
                                            required={!!isCostPlusChecked}
                                            uiDisabled={false}
                                            autoComplete="off"
                                            list="autocompleteOff"
                                            pattern="^(0|[1-9]\d*)(\.\d+)?$"
                                            uiValidityMessages={{
                                                patternMismatch:
                                                    "Cost+ is required if selected.",
                                            }}
                                        />
                                    </div>
                                </RadioLabel>
                            </div>
                        ) : (
                            <div className="flex flex-row items-center mb-2">
                                <RadioLabel className="mb-2">
                                    <RadioInput
                                        id={`${discountMsrp}Radio`}
                                        name={radioGroupName}
                                        type="radio"
                                        checked={isDiscountMsrpChecked}
                                        value={discountMsrp}
                                        onClick={(e) => {
                                            removePricingMethod(
                                                e,
                                                pricingMethodKey,
                                                isDiscountMsrpChecked
                                            );
                                        }}
                                        onChange={(e) =>
                                            handlePricingMethodRadioChange(
                                                e,
                                                pricingMethodKey
                                            )
                                        }
                                        uiReportOnBlur={false}
                                        disabled={false}
                                    />
                                    Discount MSRP
                                    <div className="flex flex-row items-center">
                                        <FormInput
                                            id={`${discountMsrp}Input`}
                                            name={`Discount MSRP ${pricingSection} Input`}
                                            className={formInputStyleClass}
                                            type="text"
                                            value={discountMsrpValue}
                                            onChange={(e) =>
                                                handlePricingInputChange(
                                                    e,
                                                    pricingValueKey
                                                )
                                            }
                                            uiReportOnBlur={false}
                                            required={
                                                isDiscountMsrpChecked
                                                //  ||
                                                // (client &&
                                                //     client.billingType ===
                                                //         pricingSection)
                                            }
                                            // uiDisabled={!isDiscountMsrpChecked}
                                            disabled={false}
                                            autoComplete="off"
                                            list="autocompleteOff"
                                            uiValidityMessages={{
                                                patternMismatch:
                                                    "MSRP Discount % is required if selected",
                                            }}
                                        />
                                        <span>%</span>
                                    </div>
                                </RadioLabel>
                            </div>
                        )}
                        <div className="flex flex-row items-center mb-2">
                            <RadioLabel>
                                <RadioInput
                                    id={`${rate}Radio`}
                                    name={radioGroupName}
                                    type="radio"
                                    checked={isRateChecked}
                                    value={rate}
                                    onClick={(e) => {
                                        removePricingMethod(
                                            e,
                                            pricingMethodKey,
                                            isRateChecked
                                        );
                                    }}
                                    onChange={(e) =>
                                        handlePricingMethodRadioChange(
                                            e,
                                            pricingMethodKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={false}
                                />
                                Rate
                            </RadioLabel>
                            <div>
                                <FormLabel htmlFor={`${rate}Input`}>
                                    $
                                </FormLabel>
                                <FormInput
                                    id={`${rate}Input`}
                                    name={`Rate ${pricingSection} Input`}
                                    className={formInputStyleClass}
                                    type="text"
                                    value={rateValue}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            pricingValueKey
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={!isRateChecked}
                                    required={isRateChecked}
                                    uiDisabled={false}
                                    autoComplete="off"
                                    list="autocompleteOff"
                                    pattern="^(0|[1-9]\d*)(\.\d+)?$"
                                    uiValidityMessages={{
                                        patternMismatch: `${pricingSection} Rate is required if selected`,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-row mt-5 bg-twBackDropColor justify-center items-center w-[90%]">
                            <span
                                className="mr-4 text-lg"
                                style={{ fontWeight: "600" }}
                            >
                                {`${pricingSection} Price`}{" "}
                            </span>
                            <span
                                className="text-lg"
                                style={{ fontWeight: "600" }}
                            >
                                {getPricingSectionPrice(pricingSection)}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const getHandlingFeeSection = () => {
        const radioGroupName = "handlingFee";
        const handlingFeeMethod = "handlingFeeMethod";
        const handlingFeeValue = "handlingFeeValue";

        const rate = "rate";
        const markUpShippingCost = "markUpShippingCost";
        const markupProductCost = "markUpProductCost";

        const isRateChecked = formFields.handlingFeeMethod === rate;
        const isMarkUpShippingChecked =
            formFields.handlingFeeMethod === markUpShippingCost;
        const isMarkUpProductChecked =
            formFields.handlingFeeMethod === markupProductCost;

        //if corresponding radios are selected, then keep the text value, else clear it
        const rateValue = isRateChecked ? formFields.handlingFeeValue : "";
        const markUpShippingValue = isMarkUpShippingChecked
            ? formFields.handlingFeeValue
            : "";
        const markUpProductValue = isMarkUpProductChecked
            ? formFields.handlingFeeValue
            : "";
        return (
            <div className="flex flex-col mt-4 ml-4">
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel>
                        <RadioInput
                            id="handlingFeeRateRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={formFields.handlingFeeMethod === rate}
                            value={rate}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked && !isRadioChecked}
                        />
                        Rate
                    </RadioLabel>
                    <div>
                        <FormLabel htmlFor="handlingFeeRateInput">$</FormLabel>
                        <FormInput
                            id="handlingFeeRateInput"
                            name={`Handling Fee Rate Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={rateValue}
                            onChange={(e) =>
                                handlePricingInputChange(e, handlingFeeValue)
                            }
                            uiReportOnBlur={false}
                            disabled={!isRateChecked}
                            required={isRateChecked}
                            uiDisabled={false}
                            autoComplete="off"
                            list="autocompleteOff"
                            pattern="^(0|[1-9]\d*)(\.\d+)?$"
                            uiValidityMessages={{
                                patternMismatch:
                                    "Handling Fee Rate is required if selected",
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="handlingFeeMarkUpShippingRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={
                                formFields.handlingFeeMethod ===
                                markUpShippingCost
                            }
                            value={markUpShippingCost}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked && !isRadioChecked}
                        />
                        Mark-up Shipping Cost
                    </RadioLabel>
                    <div>
                        <FormInput
                            id="handlingFeeMarkUpShippingInput"
                            name={`Handling Fee MarkUp Shipping Cost Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={markUpShippingValue}
                            onChange={(e) =>
                                handlePricingInputChange(e, handlingFeeValue)
                            }
                            uiReportOnBlur={false}
                            disabled={!isMarkUpShippingChecked}
                            required={isMarkUpShippingChecked}
                            uiDisabled={false}
                            autoComplete="off"
                            list="autocompleteOff"
                            pattern="^(0|[1-9]\d*)(\.\d+)?$"
                            uiValidityMessages={{
                                patternMismatch:
                                    "Mark-up Shipping Cost % is required if selected",
                            }}
                        />
                        <FormLabel htmlFor="handlingFeeMarkUpShippingInput">
                            %
                        </FormLabel>
                    </div>
                </div>
                <div className="flex flex-row items-center mb-2">
                    <RadioLabel className="mb-2">
                        <RadioInput
                            id="handlingFeeMarkUpProductRadio"
                            name={radioGroupName}
                            type="radio"
                            checked={
                                formFields.handlingFeeMethod ===
                                markupProductCost
                            }
                            value={markupProductCost}
                            onChange={(e) =>
                                handleHandleFeeRadioChange(
                                    e,
                                    handlingFeeMethod,
                                    handlingFeeValue
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={!isHandlingFeeChecked}
                            required={isHandlingFeeChecked && !isRadioChecked}
                        />
                        Mark-up Product Cost
                    </RadioLabel>
                    <div>
                        <FormInput
                            id="handlingFeeMarkUpProductInput"
                            name={`Handling Fee MarkUp Product Input`}
                            className={formInputStyleClass}
                            type="text"
                            value={markUpProductValue}
                            onChange={(e) =>
                                handlePricingInputChange(e, handlingFeeValue)
                            }
                            uiReportOnBlur={false}
                            disabled={!isMarkUpProductChecked}
                            required={isMarkUpProductChecked}
                            uiDisabled={false}
                            autoComplete="off"
                            list="autocompleteOff"
                            pattern="^(0|[1-9]\d*)(\.\d+)?$"
                            uiValidityMessages={{
                                patternMismatch:
                                    "Mark-up Product Cost % is required if selected",
                            }}
                        />
                        <FormLabel htmlFor="handlingFeeMarkUpProductInput">
                            %
                        </FormLabel>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!isShippingCostChecked) {
            dispatch({
                type: "updateFormFields",
                formFieldUpdates: {
                    shippingCost: "",
                },
            });
        }
    }, [isShippingCostChecked, dispatch]);

    useEffect(() => {
        if (!isHandlingFeeChecked) {
            dispatch({
                type: "updateFormFields",
                formFieldUpdates: {
                    handlingFeeMethod: "",
                    handlingFeeValue: "",
                },
            });
        }
    }, [isHandlingFeeChecked, dispatch]);

    return (
        <div className="flex flex-wrap w-full items-start">
            <div className="lg:w-3/4 mb-6" style={{width:'-webkit-fill-available'}}>
                <div className="flex flex-row items-center mb-2">
                    <FormLabel
                        style={
                            highlightedSection &&
                            highlightedSection.indexOf("Item Cost") >= 0
                                ? highlightStyle
                                : {}
                        }
                        className="mr-3"
                        htmlFor="cost"
                    >
                        Cost $
                    </FormLabel>

                    <FormInput
                        id="cost"
                        name="Item Cost"
                        className="px-1.5 py-2"
                        type="text"
                        value={formatPrice(formFields.cost)}
                        onChange={(e) =>
                            handlePricingInputChange(e, "cost", "dollarAmount")
                        }
                        uiReportOnBlur={false}
                        disabled={false}
                        uiDisabled={false}
                        autoComplete="off"
                        required={getIsCostRequired()}
                        list="autocompleteOff"
                        pattern="^(0|[1-9]\d*)([\.,]\d+)?$"
                        uiValidityMessages={{
                            patternMismatch:
                                "Cost is required if Mark-up Cost is selected.",
                        }}
                    />
                    <TooltipPopup
                        text={
                            <p className="font-normal">
                                The cost you incur to provide this product or service
                            </p>
                        }
                        position="top right"
                        className="floatRight"
                        size="250"
                    />
                </div>
                {!isItemTypeService && (
                    <div className="flex flex-row items-center mb-2">
                        <FormLabel
                            style={
                                highlightedSection &&
                                highlightedSection.indexOf("Item MSRP") >= 0
                                    ? highlightStyle
                                    : {}
                            }
                            className="mr-2"
                            htmlFor="itemNumber"
                        >
                            MSRP $
                        </FormLabel>
                        <FormInput
                            id="msrp"
                            name="Item MSRP"
                            className="px-1.5 py-2"
                            type="text"
                            value={formatPrice(formFields.msrp)}
                            onChange={(e) =>
                                handlePricingInputChange(
                                    e,
                                    "msrp",
                                    "dollarAmount"
                                )
                            }
                            uiReportOnBlur={false}
                            disabled={false}
                            uiDisabled={false}
                            autoComplete="off"
                            required={getIsMsrpRequired()}
                            list="autocompleteOff"
                            pattern="^(0|[1-9]\d*)([\.,]\d+)?$"
                            uiValidityMessages={{
                                patternMismatch:
                                    "MSRP is required if Discount MSRP is selected",
                            }}
                        />
                    </div>
                )}

                <div className="mt-4">
                    <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                        HOW WOULD YOU LIKE TO PRICE THIS ITEM?
                    </div>
                    <div className="mt-5 flex flex-row">
                        <div className="w-2/4 mb-6 max-w-[400px]">
                            {getPricingMethodSection(PricingSection.Retail)}
                        </div>
                        <RightCellWithBorder>
                            <div className="w-4/4 mb-6 max-w-[400px]">
                                {getPricingMethodSection(PricingSection.Trade)}
                            </div>
                        </RightCellWithBorder>
                    </div>
                </div>
                {formFields.itemTypeId === 1 && (
                    <div className="mt-4">
                        <div className="flex flex-col">
                            <div className="flex items-center font-bold tracking-wider place-content-between mt-4 mb-4 text-twBlue1">
                                SHIPPING & HANDLING
                            </div>
                            <span className="mt-4">Bill to Client:</span>
                        </div>
                        <div className="flex flex-row mt-2">
                            <Checkbox
                                checked={isShippingCostActive}
                                onClick={toggleShippingCost}
                                className="font-normal text-base mr-5"
                                required={isShippingCostActive}
                            >
                                Shipping Cost
                            </Checkbox>
                            <div>
                                <FormLabel htmlFor="shippingCostInput">
                                    ${" "}
                                </FormLabel>
                                <FormInput
                                    id="shippingCostInput"
                                    name="Shipping Cost Input"
                                    type="text"
                                    value={formFields.shippingCost}
                                    onChange={(e) =>
                                        handlePricingInputChange(
                                            e,
                                            "shippingCost"
                                        )
                                    }
                                    uiReportOnBlur={false}
                                    disabled={
                                        !isShippingCostActive &&
                                        !markUpShippingCostRequired()
                                    }
                                    required={
                                        isShippingCostActive ||
                                        markUpShippingCostRequired()
                                    }
                                    uiDisabled={false}
                                    autoComplete="off"
                                    list="autocompleteOff"
                                    className="max-w-[80px]"
                                    style={{minHeight:'35px', paddingLeft:'5px'}}
                                    pattern="^(0|[1-9]\d*)(\.\d+)?$"
                                    uiValidityMessages={{
                                        patternMismatch:
                                            "Shipping Cost is required if Bill Shipping Cost or Mark-up Shipping Cost is selected",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col mt-2">
                            <Checkbox
                                checked={isHandlingFeeActive}
                                onClick={toggleHandlingFee}
                                className="font-normal text-base mr-5"
                            >
                                Handling Fee
                            </Checkbox>
                            {getHandlingFeeSection()}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StandardItemModalPricing;
