import React, { ChangeEvent, PureComponent } from "react";
import SearchSelectResults, {
    SearchSelectResultInfo,
} from "./search_select_results";
import styled, { css, theme } from "../../../theme";

import Bind from "lodash-decorators/bind";
import ClearIconE from "../../../assets/icons/clear.svg";
import SearchIcon from "../../../assets/icons/search.png";
import { ValidationPopupInput } from "@ramble/ramble-ui";

const Container = styled.div<SearchSelectBasicProps>`
    position: relative;
    ${(props) =>
        props.searchType === "header" &&
        css`
            width: 402px;
        `}

    ${(props) =>
        props.searchType === "modal" &&
        css`
            flex: 1;
        `}

    ${(props) =>
        props.searchType === "calculator" &&
        css`
            width: 60%;
            flex: 1;
        `}
    &.newItem {
        min-width: 65%;
        max-width: 75%;
    }
    &.required-field div .search-select {
        border: 1px solid hsl(0, 71.3%, 76.2%);
        background-color: #fcf0f0;
      }
`;

const SearchInput = styled(ValidationPopupInput) <SearchInputProps>`
    padding: 0.67em 38px 0.67em 8px;
    color: rgba(27, 28, 29, 0.87);
    font-size: 15px;
    border: 1px solid hsla(0, 0%, 13.9%, 0.15);
    width: 100%;
    background: url(${SearchIcon}) #fff no-repeat 98% center;
    &.required-field {
        border: 1px solid hsl(0, 71.3%, 76.2%);
        background-color: #fcf0f0;
    }
    border-radius: 0.5em;
    // margin-bottom: 64px;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px rgb(154, 184, 124);
    }
    > input {
        font-family: Poppins, Arial, Helvetica, sans-serif;
        color: ${theme.colors.lightGrayText};
        font-style: italic;
    }

    ${(props) =>
        props.searchTextExist &&
        css`
            background: none;
        `}

    ${(props) =>
        props.searchType === "modal" &&
        css`
            height: 33.5px;
            padding: 0.35em 12% 0.35em 0.5em;
        `}
    
        ${(props) =>
        props.className === 'required-field' &&
        css`
                border: 1px solid hsl(0, 71.3%, 76.2%) !important;
                background-color: #fcf0f0 !important;
            `}
`;

const ClearIconBox = styled.span<ClearIconBoxProps>`
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
    line-height: 0;
    right: 7px;
`;

const ClearIcon = styled(ClearIconE)`
    width: 15px;
    fill: #3b87a0;
`;

const ClearIconBoxWrapper = styled.div<BoxWrapper>`
    position: relative;
    ${(props) =>
        props.searchType === "modal" &&
        props.searchPage === "myshop" &&
        props.searchItem === "client" &&
        !props.resultsDisplayed &&
        css`
            margin-bottom: 64px;
        `}
    ${(props) =>
        props.searchType === "modal" &&
        props.searchPage === "clients" &&
        props.searchItem === "client" &&
        css`
            margin-bottom: 0;
        `}
    ${(props) =>
        props.searchPage === "myshop" &&
        props.resultsDisplayed &&
        css`
            margin-bottom: 0;
        `}
`;

export interface SearchSelectBasicProps {
    searchType?: "header" | "modal" | "calculator" | "calculatorAddRoom";
    searchItem?: "client" | "supplier" | "category" | "clientForRoom" | "room" | "newItem" | "itemType";
    searchPage?: "" | "calculator" | "myshop" | "clients";
    columnType?: string;
    openModalClick?(): void;
    resultsDisplayed?: boolean;
    onFocus?(): void;
}

export interface SearchInputProps extends SearchSelectBasicProps {
    searchTextExist?: boolean;
    resultsDisplayed?: boolean;
}

interface ClearIconBoxProps {
    emptyKeyWord?: any;
    results?: any;
}
interface BoxWrapper {
    resultsDisplayed: boolean;
    searchPage?: string;
    searchType?: string;
    searchItem?: string;
}

export interface SearchSelectProps extends SearchSelectBasicProps {
    id?: string;
    itemId?: number;
    name?: string;
    placeholder?: string;
    keepKeyword?: boolean;
    required?: boolean;
    initialValue?: string;
    className?: string;
    modalSearch?: boolean;
    disabled?: boolean;
    addButtonText?: string;
    activeOnly?: boolean | undefined;
    editable?: boolean;
    onSearch(str: string): Promise<SearchSelectResultInfo[]>;
    onAddByName?(name: string): Promise<boolean>;
    onItemClick(id: number, name: string, isActive: boolean | undefined, itemId?: number): void;
    onKeywordCleared?(): void;
    setClientInfo({ }): void;
    reffererModal?: string;
    openModalClick?(): void;
    onFocus?(): void;
    displayResults?(index: number, value: boolean): void;
    highlightedSection?: string | null;
    handleEmptyField?(value: string): void;
    clearInitialValue?: boolean;
    onFocusMessage?: () => void;
    onBlurMessage?: () => void;
}

interface State {
    searchKeyword: string;
    resultsDisplayed: boolean;
    results: SearchSelectResultInfo[];
    isCustom: boolean | undefined | "";
    id: number;
    name: string;
    itemId: number;
}

class SearchSelect extends PureComponent<SearchSelectProps, State> {
    public state: State = {
        searchKeyword: this.props.initialValue || "",
        results: [],
        resultsDisplayed: false,
        isCustom: false,
        id: 0,
        name: "",
        itemId: 0,
    };

    static defaultProps: Partial<SearchSelectProps> = {
        required: false,
    };

    public componentWillReceiveProps(nextProps: Readonly<SearchSelectProps>, nextContext: any) {
        if (nextProps.clearInitialValue !== this.props.clearInitialValue && nextProps.clearInitialValue === true) {
            this.setState({ searchKeyword: "" });
        }
    }

    public componentDidUpdate(prevProps: SearchSelectProps) {
        if (
            prevProps.initialValue !== this.props.initialValue &&
            this.props.initialValue !== undefined
        ) {
            this.setState({ searchKeyword: this.props.initialValue });
        }
    }

    public render(): JSX.Element {
        const { results, resultsDisplayed, searchKeyword } = this.state;
        const propId = this.props.id;

        let searchInputId = "";
        if (propId) {
            searchInputId = propId;
        } else {
            searchInputId =
                this.props.searchType === "modal"
                    ? "selectedSearchNameOwner"
                    : "";
        }

        return (
            <Container
                className={this.props.className}
                searchType={this.props.searchType}
            >
                <ClearIconBoxWrapper
                    resultsDisplayed={resultsDisplayed}
                    searchPage={this.props.searchPage}
                    searchType={this.props.searchType}
                    searchItem={this.props.searchItem}
                >
                    {searchKeyword && !this.props.disabled && (
                        <ClearIconBox
                            onMouseDown={this.handleClearKeyword}
                            emptyKeyWord={this.state.resultsDisplayed}
                            results={this.state.results}
                        >
                            <ClearIcon />
                        </ClearIconBox>
                    )}
                    <SearchInput
                        id={searchInputId}
                        name={
                            this.props.name
                                ? this.props.name
                                : this.props.searchType === "modal"
                                    ? "selectedSearchNameOwner"
                                    : ""
                        }
                        searchTextExist={searchKeyword.length > 0}
                        searchType={this.props.searchType}
                        type="text"
                        value={searchKeyword}
                        onChange={this.changeSearchKeyword}
                        onKeyUp={this.performSearch}
                        onBlur={this.handleBlur}
                        onFocus={this.performSearch}
                        placeholder={this.props.placeholder || ""}
                        uiReportOnBlur={false}
                        required={this.props.required}
                        data-index={0}
                        autoComplete="off"
                        className="search-select"
                        disabled={this.props.disabled}
                        uiDisabled={this.props.disabled}
                        onKeyPress={this.handleKeyPress}
                        title=""
                    />
                </ClearIconBoxWrapper>

                {resultsDisplayed &&
                    (this.props.resultsDisplayed !== undefined
                        ? this.props.resultsDisplayed
                        : true) && (
                        <SearchSelectResults
                            searchItem={this.props.searchItem}
                            searchPage={this.props.searchPage}
                            searchType={this.props.searchType}
                            results={results}
                            addButtonText={this.props.addButtonText}
                            onResultClick={this.handleResultClick}
                            onClose={this.hideResults}
                            searchValue={searchKeyword}
                            openModalClick={this.props.openModalClick}
                            onFocus={this.props.onFocus}
                            resultsDisplayed={resultsDisplayed}
                            highlightedSection={this.props.highlightedSection}
                            name={this.props.name}
                        />
                    )}
            </Container>
        );
    }

    @Bind()
    private handleResultClick(id: number, name: string, itemId: number): void {
        this.setState({
            resultsDisplayed: false,
            searchKeyword: name,
            results: [],
            id,
            name,
            itemId,
        });

        this.props.onItemClick(id, name, this.props.activeOnly, itemId);
    }

    @Bind()
    private handleBlur(): void {
        if (this.props.onBlurMessage) {
            this.props.onBlurMessage()
        }
        if (this.state.resultsDisplayed) {
            this.hideResults();
        }
    }

    @Bind()
    private handleClearKeyword(): void {
        this.setState({ searchKeyword: "" });
        if (this.props.setClientInfo) {
            this.props.setClientInfo({ id: 0, itemIds: [] });
        }
        if (this.props.onKeywordCleared) {
            this.props.onKeywordCleared();
        }
        if (
            this.props.name === "draperyOrder.clientId" &&
            this.props.displayResults
        ) {
            this.props.displayResults(1, false);
        }
    }

    @Bind()
    private hideResults(): void {
        this.setState({
            resultsDisplayed: false,
        });
        if (this.props.searchType === "calculator") {
            if (this.props.onKeywordCleared) {
                if (this.props.name && this.props.name.includes('itemType')) {
                    this.setState({ searchKeyword: '' })
                    this.props.onItemClick(0, '', undefined);
                } else {
                    this.props.onItemClick(0, this.state.searchKeyword, undefined);
                }
            }
        }
    }

    @Bind()
    private changeSearchKeyword(e: ChangeEvent<HTMLInputElement>): void {
        const newValue = e.currentTarget.value;
        const isCustom = this.props.initialValue && this.props.initialValue.trim() !== newValue.trim();
        this.setState((prevState, props) => {

            return {
                isCustom,
                searchKeyword: newValue,
            };
        });

        if (this.props.handleEmptyField) {
            this.props.handleEmptyField(newValue)
        }

        const patternId: number | undefined = !this.state.isCustom ? this.state.itemId : undefined;
        if (isCustom) {
            this.props.onItemClick(this.state.id, this.state.name, this.props.activeOnly, patternId)
        }
    }

    @Bind()
    private async performSearch(): Promise<void> {
        if (this.props.disabled) {
            return;
        }

        if (this.props.onFocusMessage) {
            this.props.onFocusMessage()
        }

        let search = this.state.searchKeyword;
        search = search.includes("|") ? search.split("|")[1] : search;
        const res = await this.props.onSearch(search);
        this.setState({
            results: res,
            resultsDisplayed: true,
        });
        if (
            this.props.name === "draperyOrder.roomId" &&
            this.props.displayResults
        ) {
            this.props.displayResults(1, true);
        }
    }

    @Bind
    private async handleKeyPress(e: React.KeyboardEvent) {
        if (
            e.key === "Enter" &&
            this.state.searchKeyword &&
            this.props.onAddByName
        ) {
            const success = await this.props.onAddByName(
                this.state.searchKeyword
            );
            if (success) {
                // this.setState({
                //     resultsDisplayed: false,
                // });
            }
        }
    }
}

export default SearchSelect;
