import * as SuppliersModule from "../../redux/modules/supplier";

import { RootState } from "../../redux/modules";
import AdditionalContacts, {
    AdditionalContatctsProps,
} from "../../components/UI/additional-contact/additioal-contact-form";
import {
    Button,
    Checkbox,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    TextArea,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import React, {
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    PureComponent,
    UIEvent,
} from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled, { theme } from "../../theme";

import { AvatarModal } from "../../modals/avatar_modal";
import BillingInfo from "./supplier-billing-info";
import OrderingInfo from "./supplier-ordering-info";
import { detect } from "detect-browser";
import { formatPhoneNumber } from "../../utils/phone";
import { Config } from "../../utils";
import { uploadImage } from "../../api/account";

const noop = () => {
    return;
};

// this shit is losing the original classname when using styled()
const TabContainer = styled(TabPanel).attrs({
    className: "react-tabs__tab-panel",
})`
    height: 518px;
    overflow: auto;
    flex: 1 auto !important;
`;

export const SupplierBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: inherit;

    > div {
        margin-bottom: 11px;
        margin-top: 20px;
    }

    .label {
        width: 100%;
        border-radius: 0.5em;
    }

    ${TextArea} {
        width: 600px;
        height: 200px;
        margin-top: 15px;
        border: 1px solid #d2d8dd;
        border-radius: 0;
        box-shadow: 4px 4px 4px 1px #c1c2c3;
        padding: 2.5em 1.5em;

        &::placeholder {
            color: #338daa;
        }
    }
`;

export const LeftCell = styled.div`
    width: 50%;
    margin-bottom: 20px;
    padding-right: 1.25rem;
`;

export const RightCell = styled(LeftCell)`
    padding-right: 0;
    padding-left: 1.25rem;
`;

export const RightCellWithBorder = styled(RightCell)`
    border-left: 1px solid #338daa;
`;

export const SupplierSubtitle = styled.h4`
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-top: 0;
`;

export const SupplierLabel = styled.label`
    color: ${theme.colors.grayRGBA};
`;

export const TwoItemsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    > div {
        width: 48%;
    }
`;

export const ThreeItemsWrapper = styled(TwoItemsWrapper)`
    margin-bottom: 8px
    > div:nth-child(1) {
        width: 48%;
    }

    > div:nth-child(2) {
        width: 17%;
    }

    > div:nth-child(3) {
        width: 27%;
    }
`;

const ModalForm = styled(ValidationForm)`
    height: 100%;
    min-height: inherit;
`;

export const AdditionalForm = styled(ValidationForm)`
    display: flex;
    flex-wrap: wrap;
    padding-left: 2rem;
    margin-top: 20px;
    flex-direction: row;

    ${ThreeItemsWrapper} {
        > div:nth-child(1) {
            width: 36%;
        }

        > div:nth-child(2) {
            width: 26%;
        }

        > div:nth-child(3) {
            width: 27%;
        }
    }
`;

const AdditionalContactsMessage = styled.div`
    font-size: 16px;
    color: red;
    text-align: center;
`;

const ModalContainer = styled(Modal)`
    width: 90%;
    min-height: 300px;
    bottom: 0;
`;

const ModalHeaderSupplier = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        font-size: 26px;
    }
`;

export const SupplierFormInput = styled(ValidationPopupInput)`
    padding: 0.35em 0.5em;
`;

export const SupplierCheckbox = styled(Checkbox)`
    color: ${theme.colors.grayRGBA};
    font-size: 0.85em;

    > svg {
        width: 1.25em;
        height: 1.25em;
    }
`;

export const SupplierCheckboxSmall = styled(SupplierCheckbox)`
    font-size: 1em;
`;

const ModalContentSupplier = styled(ModalContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;

    .react-tabs__tab-list {
        border-bottom: 6px solid #338daa;
        margin-bottom: 0;
    }

    .react-tabs__tab {
        margin-right: 10px;
        border-radius: 5px 5px 0 0;
        border: 1px solid #aaa;
        border-bottom: none;
        bottom: 0;
        background-color: white;
        color: ${theme.colors.grayRGBA};

        &:last-child {
            margin-right: 0;
        }
    }

    .react-tabs__tab-panel {
        flex: 1;
    }

    .react-tabs__tab--selected {
        background-color: #338daa;
        color: #fff;
        border-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel--selected {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
    }

    .react-tabs__tab:focus::after {
        position: relative;
    }
`;

export const ModalTabs = styled(Tabs)`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;
`;

const FormErrors = styled.div`
    color: #82848b;
    overflow: hidden;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-bottom: 5px;
`;

const FormError = styled.div`
    margin: 5px;
`;

export const SaveButton = styled(Button)`
    box-shadow: none;
    display: block;
`;

export const CancelButton = styled(Button)`
    margin-right: auto !important;
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    // width: 130px;
    color: #222;
    display: block;

    &:hover {
        background-color: #eeeeee;
    }
`;

export const AddButton = styled(SaveButton)`
    width: 115px;
    margin: auto auto 0 auto;
`;

export const DisableAutocompleteInput = styled.input`
    display: none;
`;

const NameSeperator = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #9e999f;
    padding: 0 10px;
`;

const Name = styled.a`
    color: #51a6c5;
    font-size: 20px;
    flex: 1;
`;

const shippingAddressDefault =
    (): SuppliersModule.SupplierAdditionalContact => ({
        id: 0,
        accountNumber: "",
        cellPhone: "",
        cityTown: "",
        company: "",
        country: "",
        email: "",
        fax: "",
        firstName: "",
        homePhone: "",
        lastName: "",
        mainPhone: "",
        officePhone: "",
        paymentTerms: "",
        relation: "",
        stateProvince: "",
        streetAddress: "",
        unitSuite: "",
        website: "",
        zipCode: "",
    });
const billingAddressDefault =
    (): SuppliersModule.SupplierAdditionalContact => ({
        id: 0,
        accountNumber: "",
        cellPhone: "",
        cityTown: "",
        company: "",
        country: "",
        email: "",
        fax: "",
        firstName: "",
        homePhone: "",
        lastName: "",
        mainPhone: "",
        officePhone: "",
        paymentTerms: "",
        relation: "",
        stateProvince: "",
        streetAddress: "",
        unitSuite: "",
        website: "",
        zipCode: "",
    });

const supplierDefaults = (): SuppliersModule.Supplier => ({
    id: 0,
    active: true,
    avatarId: "",
    accountNumber: "",
    mainPhone: "",
    displayName: "",
    companyName: "",
    fax: "",
    notes: "",
    tags: "",
    type: "installer",
    shippingAddress: shippingAddressDefault(),
    billingAddress: billingAddressDefault(),
    additionalContacts: [],
});

interface AddSupplierProps {
    isModalOpen: boolean;
    resetOnClose: boolean;
    accountId: number;
    initialValues?: SuppliersModule.Supplier;
    onSave(supplier: SuppliersModule.Supplier): Promise<void>;
    onModalClose(): void;
    setFocusFirstField?(fn: any): void;
}

interface State {
    fields: SuppliersModule.Supplier;
    formErrors: string[];
    tabIndex: number;
    imageModalOpen: boolean;
    isSameOrderingInfo: boolean;
    areaScrollInfo: {
        scrollX: number;
        scrollY: number;
    };
    isCustomDisplayName: boolean;
    stateList: string[];
    additionalFormOpen: boolean;
    isOpen: boolean;
    customSupplier?: boolean;
}

export class SupplierModal extends PureComponent<
    AddSupplierProps & DispatchProp,
    State
> {
    private orderingInfoRef = React.createRef<OrderingInfo>();

    public constructor(props: AddSupplierProps & DispatchProp) {
        super(props);

        const { initialValues } = this.props;
        const stateList: string[] = [
            "AL",
            "AK",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "DR",
            "FL",
            "GA",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "OH",
            "OK",
            "OR",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "PA",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VA",
            "WA",
            "WV",
            "WI",
            "WY",
        ];
        const sortedStateList = stateList.sort((a: string, b: string) =>
            a > b ? 1 : -1
        );

        this.state = {
            fields: initialValues
                ? {
                      ...initialValues,
                      additionalContacts: [...initialValues.additionalContacts],
                      billingAddress: {
                          ...initialValues.billingAddress,
                      },
                      shippingAddress: {
                          ...initialValues.shippingAddress,
                      },
                  }
                : supplierDefaults(),
            isOpen: false,
            formErrors: [],
            tabIndex: 0,
            imageModalOpen: false,
            areaScrollInfo: {
                scrollX: 0,
                scrollY: 0,
            },
            isCustomDisplayName: false,
            isSameOrderingInfo: false,
            stateList: sortedStateList,
            additionalFormOpen: false,
            customSupplier: initialValues && initialValues.customSupplier,
        };
    }

    public componentDidMount() {
        if (this.props.setFocusFirstField) {
            this.props.setFocusFirstField(this.setFocusOnFirstField);
        }
    }

    public setFocusOnFirstField = () => {
        if (this.orderingInfoRef.current) {
            this.orderingInfoRef.current.setFocusOnFirstField();
        }
    };

    public render(): JSX.Element {
        const { fields } = this.state;

        return (
            <>
                <PageDimmer uiActive={this.props.isModalOpen} />
                <AvatarModal
                    uiActive={this.state.imageModalOpen}
                    uiOnRequestClose={this.closeAvatarModal}
                    onSave={this.saveAvatarImage}
                />
                <ModalContainer
                    uiActive={this.props.isModalOpen}
                    uiOnRequestClose={noop}
                    uiDefaultSizes={false}
                >
                    <ModalForm
                        id="supplier"
                        uiOnSubmit={this.submitForm}
                        uiPadding={false}
                        uiReportValidityMode="firstInvalid"
                        uiOnInvalidElem={this.handleInvalidElem}
                        onKeyPress={this.handleKeyPressForm}
                    >
                        <DisableAutocompleteInput name="firstName" />
                        <ModalHeaderSupplier>
                            <div>
                                <span className="modal-title">
                                    Supplier Profile
                                </span>
                                {this.state.fields.displayName && (
                                    <>
                                        <NameSeperator> - </NameSeperator>
                                        <Name>{`${this.state.fields.displayName}`}</Name>
                                    </>
                                )}
                                <SupplierCheckbox
                                    checked={!fields.active}
                                    onClick={this.handleChangeInactiveSupplier}
                                >
                                    Make Inactive
                                </SupplierCheckbox>
                            </div>
                        </ModalHeaderSupplier>
                        <ModalContentSupplier>
                            <FormErrors>
                                {this.state.formErrors.map((error) => (
                                    <FormError>{error}</FormError>
                                ))}
                            </FormErrors>

                            <PopoverScrollContext.Provider
                                value={this.state.areaScrollInfo}
                            >
                                <ModalTabs
                                    selectedIndex={this.state.tabIndex}
                                    onSelect={this.selectTab}
                                    forceRenderTabPanel
                                >
                                    <TabList>
                                        <Tab>Ordering Info</Tab>
                                        <Tab>Billing Info</Tab>
                                        <Tab>Additional Contacts</Tab>
                                        <Tab>Notes</Tab>
                                    </TabList>
                                    {this.renderOrderingInfo()}

                                    {this.renderBillingInfo()}

                                    {this.renderAdditionalContactInfo()}

                                    {this.renderNotes()}
                                    <AdditionalContactsMessage>
                                        {this.state.isOpen
                                            ? 'Please save or close "Additional Contacts" form'
                                            : ""}
                                    </AdditionalContactsMessage>
                                </ModalTabs>
                            </PopoverScrollContext.Provider>
                        </ModalContentSupplier>

                        <ModalActions>
                            <CancelButton
                                type="button"
                                uiColor="secondary"
                                onClick={this.closeModal}
                            >
                                Cancel
                            </CancelButton>

                            <SaveButton
                                type="submit"
                                form="supplier"
                                uiColor="primary"
                                disabled={this.state.additionalFormOpen}
                                uiDisabled={this.state.additionalFormOpen}
                            >
                                Save
                            </SaveButton>
                        </ModalActions>
                    </ModalForm>
                </ModalContainer>
            </>
        );
    }

    public renderOrderingInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <OrderingInfo
                    ref={this.orderingInfoRef}
                    formFields={this.state.fields}
                    openAvatarModal={this.openAvatarModal}
                    deleteAvatarImage={this.deleteAvatarImage}
                    onSelectChange={this.handleSelectChange}
                    onInputChange={this.handleInputChange}
                    stateList={this.state.stateList}
                    tags={this.state.fields.tags}
                    onTagsChanged={this.onTagsChanged}
                    handleChangeDisplayName={this.handleChangeDisplayName}
                />
            </TabContainer>
        );
    }

    public renderBillingInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <BillingInfo
                    formFields={this.state.fields}
                    stateList={this.state.stateList}
                    onSelectChange={this.handleSelectChange}
                    onInputChange={this.handleInputChange}
                    handleChangeSameInformationCheckbox={
                        this.handleChangeSameInformationCheckbox
                    }
                    isSameInfo={this.state.isSameOrderingInfo}
                />
            </TabContainer>
        );
    }

    public renderAdditionalContactInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <AdditionalContacts
                    contacts={this.state.fields.additionalContacts}
                    stateList={this.state.stateList}
                    additionalFormOpen={this.state.additionalFormOpen}
                    onAdditionalContactsChanged={this.changeAdditionalContacts}
                    deleteContact={this.deleteAdditionalContact}
                    setAdditionalFormStatus={this.setAdditionalFormStatus}
                    updateParentState={this.updateParentState}
                />
            </TabContainer>
        );
    }

    public renderNotes(): JSX.Element {
        const browser = detect();
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <SupplierBlock>
                    <div>
                        <TextArea
                            name="notes"
                            placeholder="NOTES"
                            onChange={this.handleInputChange}
                            value={this.state.fields.notes}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                        />
                    </div>
                </SupplierBlock>
            </TabContainer>
        );
    }

    public selectTab = (index: number) => {
        this.setState({
            tabIndex: index,
            areaScrollInfo: {
                scrollX: 0,
                scrollY: 0,
            },
        });
    };

    private onTagsChanged = (newTags: string) => {
        this.setState({
            fields: {
                ...this.state.fields,
                tags: newTags,
            },
        });
    };

    private onTabAreaScoll = (e: UIEvent<HTMLDivElement>) => {
        const { scrollTop } = e.currentTarget;
        this.setState({
            areaScrollInfo: {
                scrollX: 0,
                scrollY: scrollTop,
            },
        });
    };

    private closeModal = () => {
        if (this.state.additionalFormOpen) {
            this.setState({ isOpen: true });
            return;
        }
        if (this.props.resetOnClose) {
            const { initialValues } = this.props;

            let fields: SuppliersModule.Supplier;
            if (initialValues) {
                fields = {
                    ...initialValues,
                    additionalContacts: [...initialValues.additionalContacts],
                    billingAddress: {
                        ...initialValues.billingAddress,
                    },
                    shippingAddress: {
                        ...initialValues.shippingAddress,
                    },
                };
            } else {
                fields = supplierDefaults();
            }

            this.setState({
                fields,
                formErrors: [],
                tabIndex: 0,
                imageModalOpen: false,
                areaScrollInfo: {
                    scrollX: 0,
                    scrollY: 0,
                },
                isSameOrderingInfo: false,
            });
        }
        this.props.onModalClose();
    };

    private handleSelectChange = (value: string, name: string) => {
        this.procesChange(value, name);
    };

    private handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        this.procesChange(e.currentTarget.value, e.currentTarget.name);
    };

    private changeAdditionalContacts: AdditionalContatctsProps["onAdditionalContactsChanged"] =
        (contacts) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    additionalContacts: contacts,
                },
                additionalFormOpen: false,
            });
        };

    private procesChange = (value: string, name: string) => {
        if (!name) {
            return;
        }
        const newFields: SuppliersModule.Supplier = {
            ...this.state.fields,
            shippingAddress: {
                ...this.state.fields.shippingAddress,
            },
            billingAddress: {
                ...this.state.fields.billingAddress,
            },
        };
        let finalValue = value;
        let finalKey = name;
        let field: any = newFields;
        if (name.endsWith("Phone")) {
            finalValue = formatPhoneNumber(value);
        }
        if (
            name === "companyName" &&
            !this.state.isCustomDisplayName &&
            !this.state.customSupplier
        ) {
            field.displayName = finalValue;
        }

        if (name.startsWith("shippingAddress.")) {
            field = newFields.shippingAddress;
            finalKey = name.split(".").pop()!;
        } else if (name.startsWith("billingAddress.")) {
            field = newFields.billingAddress;
            finalKey = name.split(".").pop()!;
        }
        field[finalKey] = finalValue;

        this.setState({
            fields: newFields,
        });
    };

    private submitForm = async (e: FormEvent<HTMLFormElement>) => {
        const { fields } = this.state;
        const { onSave } = this.props;
        await onSave(fields);
        this.closeModal();
    };

    private handleChangeInactiveSupplier = () => {
        this.setState({
            fields: {
                ...this.state.fields,
                active: !this.state.fields.active,
            },
        });
    };

    private closeAvatarModal = () => {
        this.setState({
            imageModalOpen: false,
        });
    };

    private saveAvatarImage = async (file: File) => {
        if (file.size > 2 * 1024 * 1024) {
            try {
                file = await this.compressImage(file);
            } catch (error) {
                throw new Error(
                    "An error occurred while compressing the image."
                );
            }
        }
        const { accountId } = this.props;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("accountId", accountId.toString());
        formData.append("fileStoreUrl", Config.fileStoreUrl);
        const res = await uploadImage(formData);
        if (res.error) {
            throw new Error(res.message);
        }
        this.setState({
            fields: {
                ...this.state.fields,
                avatarId: res.url,
            },
            imageModalOpen: false,
        });
    };

    private compressImage = (file: File): Promise<File> => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const target = event.target as FileReader;
                if (!target || !target.result) return;
                const img = new Image();
                img.src = target.result as string;
                img.onload = () => {
                    const maxWidth = 1024;
                    const maxHeight = 1024;
                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth || height > maxHeight) {
                        const aspectRatio = width / height;
                        if (width > height) {
                            width = maxWidth;
                            height = width / aspectRatio;
                        } else {
                            height = maxHeight;
                            width = height * aspectRatio;
                        }
                    }

                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    if (!ctx) {
                        resolve(file);
                        return;
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            if (!blob) {
                                resolve(file);
                                return;
                            }
                            const compressedFile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: file.lastModified,
                            });
                            resolve(compressedFile);
                        },
                        "image/jpeg",
                        0.7
                    );
                };
            };

            reader.readAsDataURL(file);
        });
    };

    private openAvatarModal = () => {
        this.setState({
            imageModalOpen: true,
        });
    };

    private deleteAvatarImage = () => {
        this.setState({
            fields: {
                ...this.state.fields,
                avatarId: undefined,
            },
        });
    };

    private handleInvalidElem = (e: HTMLElement): void => {
        if (e.dataset.index && +e.dataset.index !== this.state.tabIndex) {
            this.setState({ tabIndex: +e.dataset.index }, () => {
                e.scrollIntoView({ behavior: "smooth", block: "center" });
            });
        } else {
            e.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    private handleChangeDisplayName = (value: string) => {
        const { companyName } = this.state.fields;
        const isCustomDisplayName = value !== "" && value !== `${companyName}`;
        this.setState({
            fields: {
                ...this.state.fields,
                displayName: value,
                customSupplier: value !== `${companyName}` ? true : false,
            },
            isCustomDisplayName,
        });
    };

    private handleChangeSameInformationCheckbox = (
        e: FormEvent<HTMLInputElement>
    ) => {
        this.setState({ isSameOrderingInfo: e.currentTarget.checked }, () => {
            if (this.state.isSameOrderingInfo) {
                this.preFillFields(this.state.fields.billingAddress);
            }
        });
    };

    private preFillFields = (
        billingAddress: SuppliersModule.SupplierAdditionalContact
    ): void => {
        billingAddress = billingAddressDefault();
        const updatedFields = {
            ...this.state.fields,
        };

        for (const key in updatedFields) {
            if (billingAddress.hasOwnProperty(key)) {
                billingAddress[key] = updatedFields[key];
            }
        }
        for (const key in updatedFields.shippingAddress) {
            if (
                billingAddress.hasOwnProperty(key) &&
                billingAddress[key] === ""
            ) {
                billingAddress[key] = updatedFields.shippingAddress[key];
            }
        }
        if (updatedFields.companyName) {
            billingAddress.company = updatedFields.companyName;
        }
        updatedFields.billingAddress = billingAddress;
        this.setState({ fields: updatedFields });
    };

    private deleteAdditionalContact = (index: number): void => {
        const updateAdditionalContacts =
            this.state.fields.additionalContacts.filter((_, i) => i !== index);

        this.setState({
            fields: {
                ...this.state.fields,
                additionalContacts: updateAdditionalContacts,
            },
        });
    };

    private handleKeyPressForm(e: KeyboardEvent<HTMLElement>) {
        if (
            e.which === 13 &&
            (e.target as HTMLTextAreaElement).name !== "notes"
        ) {
            e.preventDefault();
        }
    }

    private setAdditionalFormStatus = (status: "open" | "closed"): void => {
        this.setState({
            additionalFormOpen: status === "open",
        });
    };
    updateParentState = () => {
        this.setState({ isOpen: false });
    };
}

function mapStateToProps(
    state: RootState,
    ownProps: AddSupplierProps
): AddSupplierProps {
    return {
        ...ownProps,
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
    };
}

export default connect(mapStateToProps)(SupplierModal);
