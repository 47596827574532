import {
    Button,
    FormField,
    FormValidatorErrorMap,
    ValidationForm,
    ValidationPopupCheckbox,
    ValidationPopupInput,
    ValidationPopupStateInput,
    ValidityMessagesMap,
} from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import { FaEye, FaLock, FaUser } from "react-icons/fa";
import React, { FormEvent, PureComponent } from "react";

import { AuthHeader } from "../components/auth/auth_header";
import Bind from "lodash-decorators/bind";
import { Logo } from "../components/auth/logo";
import { RouteComponentProps } from "react-router-dom";
import { RouteLink } from "../components/navigation/route_link";
import { TermMessage } from "../components/auth/term_message";
// import { UserModule } from "../redux";
import { seedItemsOnSignup } from "../api/item";
import styled from "../theme";
import { createCrispProfile } from "../api/crisp";
import { createCustomer } from "../api/stripe";
import {
    populateBillingInfo,
    sendVerifyEmail,
    emailVerification,
    checkVerification,
} from "../api/signup";
import { BotInfo, BrowserInfo, NodeInfo } from "detect-browser";
import { addWorkroomSetting } from "../api/workroom-setting";
import { signUp, validateEmail } from "../api/signup";

const LoginLink = styled(RouteLink)`
    font-size: 0.8em;
`;

const SignupForm = styled(ValidationForm)`
    padding: 0 1em;
`;

const StyledTermsCheckbox = styled(ValidationPopupCheckbox)`
    > svg {
        vertical-align: top !important;
    }
`;

const StyledTermsMessage = styled(TermMessage)`
    text-align: left;
    font-size: 0.8em;
    margin-bottom: 2px;
`;

// tslint:disable-next-line:no-empty-interface
export interface SignupProps extends RouteComponentProps {}

interface SignupState {
    valueNew: boolean;
    valueResetNew: boolean;
    string: string;
    stringNew: string;
    password: string;
    termsAccepted: boolean;
    submitAttempted: boolean;
    browser: BrowserInfo | BotInfo | NodeInfo | null;
    emailExist: boolean;
    emailVerified: boolean;
    successMessage: string;
    email: string;
    alreadyVerified: boolean;
}

const InputErrorMessage = styled.div`
    color: red;
    font-size: 13px;
    p {
        margin-top: 7px !important;
    }

    .error-msg {
        display: none !important;
    }
`;

const InputSuccessMessage = styled.div`
    color: rgba(255, 0, 0, 0.8);
    font-size: 1em;
    margin-bottom: 7px !important;
    p {
        margin-top: 7px !important;
    }
`;

export class Signup extends PureComponent<
    SignupProps & DispatchProp,
    SignupState
> {
    public constructor(props: SignupProps & DispatchProp) {
        super(props);
        this.state = {
            valueNew: false,
            valueResetNew: false,
            string: "",
            stringNew: "",
            password: "",
            termsAccepted: false,
            submitAttempted: false,
            browser: null,
            emailExist: false,
            emailVerified: false,
            successMessage: "",
            email: "",
            alreadyVerified: false,
        };
    }

    private validationMessages: ValidityMessagesMap<
        "username" | "email" | "password"
    > = {
        username: {
            nonUnique: "An account with that Username already exists",
            patternMismatch: "Only alphanum characters are allowed",
        },
        email: {
            nonUnique: "An account with that Email already exists",
            typeMismatch: "Invalid email",
            patternMismatch: "Invalid email",
        },
        password: {
            tooShort: "Must be at least 8 characters.",
            patternMismatch:
                "Must contain at least 8 characters including an uppercase letter, lowercase letter, and a number or special character.",
        },
    };

    componentDidMount() {
        const emailInput = document.getElementById(
            "email"
        ) as HTMLInputElement | null;
        if (emailInput) {
            emailInput.value = "";
        }

        let tokenValue: string | undefined;

        if (typeof window !== "undefined") {
            const currentPath = window.location.href;
            if (currentPath.includes("?")) {
                // Extract the query string part of the URL
                const queryString = currentPath.split("?")[1];

                // Split the query string into key-value pairs
                const queryParams = queryString.split("&");

                // Loop through the key-value pairs to find the token parameter
                for (const param of queryParams) {
                    const [key, value] = param.split("=");
                    if (key === "token") {
                        tokenValue = value;
                        break;
                    }
                }
            } else {
                console.log("URL does not contain a query string");
            }
        } else {
            console.log(
                "Not in a browser environment, unable to access window.location"
            );
        }

        if (tokenValue) {
            checkVerification(tokenValue)
                .then((isEmailVerified) => {
                    if (isEmailVerified) {
                        this.setState({ alreadyVerified: true });
                    } else {
                        this.setState({ alreadyVerified: false });
                        this.verifyEmail(tokenValue);
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.error(
                        "Error occurred during email verification:",
                        error
                    );
                });
        }

        window.addEventListener("storage", this.handleStorageChange);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("storage", this.handleStorageChange);
    }

    private handleStorageChange = (event: StorageEvent) => {
        if (event.key === "status" && event.newValue !== null) {
            window.location.replace("/overview");
        }
    };

    public render(): JSX.Element {
        return (
            <>
                <Logo />
                <AuthHeader>
                    Join QUIPA
                    <LoginLink to="/login">Already a member? Login</LoginLink>
                </AuthHeader>
                <SignupForm
                    uiOnSubmit={this.signup}
                    uiPadding={false}
                    uiFormValidator={this.formValidator}
                    uiSubmitFailedIcon
                    uiReportValidityMode="firstInvalid"
                    uiDimmerBlurring={false}
                >
                    {this.state.successMessage && (
                        <InputSuccessMessage>
                            {this.state.successMessage}
                        </InputSuccessMessage>
                    )}
                    <FormField
                        uiRequired
                        uiErrorAlignment="left"
                        style={{ position: "relative" }}
                    >
                        <ValidationPopupStateInput
                            id="email"
                            name="email"
                            type="email"
                            // uiDisabled
                            // value={this.state.email}
                            required
                            tabIndex={2}
                            maxLength={255}
                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                            placeholder="E-Mail"
                            uiCustomAsyncValidator={this.uniquenessValidator}
                            uiValidityMessages={this.validationMessages.email}
                            uiReportAsyncValidationPending
                            uiReportResultTimeout={3000}
                            uiPopupPosition="top right"
                            uiReportOnBlur={false}
                            style={{ marginLeft: "15px" }}
                            onChange={this.handleInputChange}
                            autoComplete={
                                this.state.browser &&
                                this.state.browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                            list="autocompleteOff"
                        />
                        <FaUser
                            style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                            }}
                        />
                    </FormField>
                    <InputErrorMessage>
                        {this.state.emailExist && (
                            <p>An account with that Email already exists</p>
                        )}
                    </InputErrorMessage>
                    <FormField
                        uiRequired
                        uiErrorAlignment="left"
                        style={{ position: "relative" }}
                    >
                        <ValidationPopupInput
                            id="firstname"
                            name="firstname"
                            required
                            tabIndex={3}
                            maxLength={255}
                            placeholder="First Name"
                            uiPopupPosition="top right"
                            uiReportOnBlur={false}
                            style={{ marginLeft: "15px" }}
                            type="search"
                            autoComplete="off"
                            role="presentation"
                            onChange={this.handleInputChange}
                        />
                        <FaUser
                            style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                            }}
                        />
                    </FormField>
                    <FormField
                        uiRequired
                        uiErrorAlignment="left"
                        style={{ position: "relative" }}
                    >
                        <ValidationPopupInput
                            id="lastname"
                            name="lastname"
                            required
                            tabIndex={3}
                            maxLength={255}
                            placeholder="Last Name"
                            uiPopupPosition="top right"
                            uiReportOnBlur={false}
                            style={{ marginLeft: "15px" }}
                            type="search"
                            autoComplete="off"
                            role="presentation"
                            onChange={this.handleInputChange}
                        />
                        <FaUser
                            style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                            }}
                        />
                    </FormField>
                    <FormField
                        uiRequired
                        uiErrorAlignment="left"
                        style={{ position: "relative" }}
                    >
                        <ValidationPopupInput
                            id="password"
                            name="password"
                            type="password"
                            role="presentation"
                            tabIndex={4}
                            required
                            minLength={8}
                            maxLength={255}
                            // uppercase, lowercase, number/special chars and min 8 chars
                            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                            placeholder="Password"
                            uiPopupPosition="top right"
                            autoComplete="off"
                            uiReportOnBlur={false}
                            uiValidityMessages={
                                this.validationMessages.password
                            }
                            className="eye"
                            style={{ marginLeft: "15px" }}
                            onChange={(e) => {
                                this.handleChangePassword(e);
                                this.handleInputChange();
                            }}
                            popupString={true}
                        />
                        <FaEye
                            style={{
                                position: "absolute",
                                right: "15px",
                                top: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() => this.handleShowPassword("password")}
                        />
                        <FaLock
                            style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                            }}
                        />
                    </FormField>
                    <InputErrorMessage>
                        {!this.state.valueNew &&
                            this.state.string.length > 0 && (
                                <p>
                                    Must contain at least 8 characters including
                                    an uppercase letter, lowercase letter, and a
                                    number or special character.
                                </p>
                            )}
                    </InputErrorMessage>
                    <FormField
                        uiRequired
                        uiErrorAlignment="left"
                        style={{ position: "relative" }}
                    >
                        <ValidationPopupInput
                            id="password_repeat"
                            name="password_repeat"
                            type="password"
                            tabIndex={5}
                            minLength={8}
                            maxLength={255}
                            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                            placeholder="Confirm Password"
                            uiPopupPosition="top right"
                            autoComplete="off"
                            role="presentation"
                            uiReportOnBlur={false}
                            uiValidityMessages={{
                                patternMismatch: "Passwords must match",
                            }}
                            style={{ marginLeft: "15px" }}
                            onChange={(e) => {
                                this.handleChangePasswordRepeat(e);
                                this.handleInputChange();
                            }}
                            popupString={true}
                        />
                        <FaEye
                            style={{
                                position: "absolute",
                                right: "15px",
                                top: "15px",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                this.handleShowPassword("password_repeat")
                            }
                        />
                        <FaLock
                            style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                            }}
                        />
                    </FormField>
                    <InputErrorMessage>
                        {this.state.password && <p>{this.state.password}</p>}
                    </InputErrorMessage>
                    <FormField>
                        <StyledTermsCheckbox
                            id="terms_agree"
                            name="terms_agree"
                            type="checkbox"
                            required
                            tabIndex={6}
                            uiPopupDistanceAway={0}
                            uiPopupOffset={-10}
                            onChange={() => {
                                this.handleTermsCheckboxChange();
                                this.handleInputChange();
                            }}
                        >
                            <StyledTermsMessage>
                                By joining, you agree to our{" "}
                                <a
                                    href="https://www.quipa.com/terms-of-use"
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://www.quipa.com/privacy-policy"
                                    target="_blank"
                                >
                                    Privacy Policy.
                                </a>
                            </StyledTermsMessage>
                        </StyledTermsCheckbox>
                        {!this.state.termsAccepted &&
                            this.state.submitAttempted && (
                                <InputErrorMessage>
                                    <p>
                                        Please check this box if you want to
                                        proceed.
                                    </p>
                                </InputErrorMessage>
                            )}
                    </FormField>
                    <Button
                        uiDisabled={this.state.submitAttempted}
                        uiColor="primary"
                        type="submit"
                        tabIndex={7}
                        onClick={this.handleJoinButtonClick}
                    >
                        JOIN
                    </Button>
                </SignupForm>
            </>
        );
    }

    @Bind()
    private async verifyEmail(token: string | undefined): Promise<void> {
        if (token) {
            try {
                const email = await emailVerification(token);
                if (email !== "") {
                    this.setState({ emailVerified: true, email: email });
                } else {
                    this.setState({ emailVerified: false });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({ emailVerified: false });
        }
    }

    @Bind()
    private async signup(e: FormEvent<HTMLFormElement>): Promise<void> {
        const {
            //  dispatch,
            // history,
        } = this.props;
        /* tslint:disable:no-string-literal */
        const elements = e.currentTarget.elements;
        const email = (elements["email"] as HTMLInputElement).value.trim();
        const firstName = (
            elements["firstname"] as HTMLInputElement
        ).value.trim();
        const lastName = (
            elements["lastname"] as HTMLInputElement
        ).value.trim();

        const password = (elements["password"] as HTMLInputElement).value;

        // const res = await dispatch(
        //     UserModule.signup({
        //         email,
        //         firstName,
        //         lastName,
        //         password,
        //         peopleID: "",
        //     })
        // );

        const payload = {
            email,
            firstName,
            lastName,
            password,
            peopleID: "",
        };

        const res = await signUp(payload);

        if (res.error) {
            throw new Error(res.message);
        } else {
            localStorage.setItem("showModal", "true");
            let objectLocal: any = {
                user: {
                    email: res.user.email,
                    token: res.token,
                    refreshToken: res.refreshToken,
                },
            };

            objectLocal = JSON.stringify(objectLocal);

            localStorage.setItem("quipa-store", objectLocal as string);
            localStorage.setItem("status", res.account.type);
            localStorage.setItem("accountId", res.account.id);
            localStorage.setItem("firstName", res.user.firstName);
            localStorage.setItem("lastName", res.user.lastName);
            localStorage.setItem("role", res.user.role);
            localStorage.setItem("avatarId", res.account.avatarId);
            if (res.user.logoId) {
                localStorage.setItem("logoId", res.user.logoId);
            }
        }
        if (res.account.loginAllowed) {
            // history.push("/");
            window.location.reload();
        } else {
            const accountId = res.account.id;
            await createCustomer(accountId);
            await createCrispProfile(accountId);
            await this.handleVerify(email);
            try {
                await seedItemsOnSignup();
                const defaultItemList = [
                    { type: 1, name: "Drapery | Full Length" },
                    { type: 2, name: "Drapery | Cafe Curtain" },
                    { type: 3, name: "Drapery | Ripplefold" },
                ];

                for (const defaultItem of defaultItemList) {
                    const draperyFullnessValue =
                        defaultItem.type === 3 ? 1 : 2.5;

                    try {
                        const result = await addWorkroomSetting({
                            accountId,
                            type: defaultItem.type,
                            name: defaultItem.name,
                            presetIsYardageAddOneExtra: true,
                            presetIsYardageAddExcess: false,
                            buckram: "Ask for each order",
                            presetAddExcessUnit: "%",
                            presetDraperyFullnessJson: {
                                value: draperyFullnessValue,
                            },
                        });

                        if (result.error) {
                            throw new Error(result.payload.message);
                        }

                        // Logger.debug(`Default Workroom setting for ${defaultItem.name} created`);
                    } catch (error) {
                        // Logger.error(
                        //     `Failed to create Workroom setting for ${defaultItem.name}: ${error.message}`
                        // );
                    }
                }
                await populateBillingInfo(accountId);
            } catch (err) {
                console.log("err: ", err);
            }

            // history.push("/card");
        }
    }

    @Bind()
    private handleTermsCheckboxChange() {
        this.setState((prevState) => ({
            termsAccepted: !prevState.termsAccepted,
        }));
    }

    @Bind()
    private handleJoinButtonClick() {
        this.setState({ submitAttempted: true });
    }

    @Bind()
    private handleInputChange() {
        this.setState({ submitAttempted: false });
    }

    @Bind()
    private async handleVerify(email: string): Promise<void> {
        try {
            const res = await sendVerifyEmail(email);
            if (
                res.hasOwnProperty("isVerified") &&
                res.hasOwnProperty("alreadyVerified")
            ) {
                if (res.isVerified && res.alreadyVerified) {
                    this.setState({ successMessage: "" });
                    this.setState({
                        emailVerified: false,
                        alreadyVerified: true,
                    });
                } else if (res.isVerified && !res.alreadyVerified) {
                    this.setState({
                        emailVerified: true,
                        alreadyVerified: false,
                        email: email,
                    });
                } else {
                    this.setState({
                        emailVerified: false,
                        alreadyVerified: false,
                    });
                    this.setState({
                        successMessage:
                            "A verification link has been sent to your email. Please open the email and click the link to proceed.",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    @Bind()
    private formValidator(elem: HTMLFormElement): FormValidatorErrorMap {
        // tslint:disable-next-line:no-string-literal
        const password = (elem.elements["password"] as HTMLInputElement).value;
        // tslint:disable-next-line:no-string-literal
        const repeatPassword = (
            elem.elements["password_repeat"] as HTMLInputElement
        ).value;
        if (password !== repeatPassword) {
            this.setState({ password: "Passwords must match" });
            return {
                password_repeat: "Passwords must match",
            };
        }
        this.setState({ password: "" });

        return {};
    }

    @Bind()
    private async uniquenessValidator(
        val: string,
        elem: HTMLInputElement
    ): Promise<{ [key: string]: boolean } | undefined> {
        // const { dispatch } = this.props;
        if (elem.id === "email") {
            // const res = await dispatch(UserModule.validateEmail(val.trim()));

            const res = await validateEmail(val.trim());
            if (
                res.error &&
                res.message &&
                res.message.includes("Email already in use")
            ) {
                this.setState({ emailExist: true });
                return {
                    emailExist: true,
                };
            } else if (res.error) {
                this.setState({ emailExist: false });
                return {
                    emailExist: false,
                };
            } else {
                this.setState({ emailExist: false });
                return {};
            }
        }
    }

    @Bind()
    private handleShowPassword(fieldId: string) {
        const eye = document.getElementById(fieldId)! as HTMLInputElement;
        if (eye.type === "password") {
            eye.type = "text";
        } else if (eye.type === "password_repeat") {
            eye.type = "text";
        } else {
            eye.type = "password";
        }
    }

    @Bind()
    private handleChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.setState({ string: value });
        const regex =
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        if (value.match(regex)) {
            this.setState({ valueNew: true });
        } else {
            this.setState({ valueNew: false });
        }
    }

    @Bind()
    private handleChangePasswordRepeat(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.setState({ stringNew: value });

        const passwordValue = (
            document.getElementById("password") as HTMLInputElement
        ).value;

        if (passwordValue.trim() !== "" && value.trim() !== "") {
            if (value !== passwordValue) {
                this.setState({
                    password: "Passwords must match",
                    valueResetNew: true,
                });
            } else {
                this.setState({ password: "", valueResetNew: true });
            }
        } else {
            this.setState({ password: "", valueResetNew: false });
        }
    }
}

export const SignupContainer = connect()(Signup);
