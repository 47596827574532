import React, { FC, memo, useState, useEffect, useRef } from "react";

import { Header } from "@ramble/ramble-ui";
import { FilterModal } from "../filter-modal/filter-modal";
import { Category } from "../../../interfaces/category";
import { Supplier } from "../../../interfaces/supplier";
import { FiXCircle } from "react-icons/fi";
import styled, { css } from "../../../theme";
import SearchSelect from "../../UI/search_select/search_select";
import { FilterValues } from "../my-shop";
import { CalculatorRadio } from "../../calculator/styles";
import { Button } from "@ramble/ramble-ui";

export interface PageHeaderProps {
    /**
     * Page title
     */
    title: string;
    classNameProps?: string;
    activeOnly?: boolean | undefined;
    onSearch: (params: any) => void;
    onItemClick: (
        id: number,
        name: string,
        isActive: boolean | undefined
    ) => void;
    onFilter: (params: FilterValues) => void;
    inputRef: any;
    suppliers: Supplier[];
    categories: Category[];
    clearFilter: () => void;
    setClientInfo({}): void;
    setActiveOnly: (value: boolean) => void;
    priceRadio?: string;
    setPriceRadio?: (value: string) => void;
}

const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 63px;
    z-index: 10;
    background-color: ${(props) => {
        // console.log("Background Color Props:", props);
        return (
            props.className === "true" &&
            css`
            #f8f9f9 !important;
            `
        );
    }};
`;

const StyledHeader = styled(Header)`
    margin: 10px;
    font-family: "Poppins", serif;
    font-size: 18px;
    letter-spacing: 0px;
    color: #222222;
    font-weight: 400;
    align-items: center;
`;

const Content = styled.div`
    align-self: flex-end;
`;
const PriceFilter = styled.input`
    &:focus-within {
        border-color: #3b97b1 !important;
    }
    &:focus-within {
        outline: transparent;
    }
`;
export const PageHeader: FC<PageHeaderProps> = memo((props) => {
    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    const [filterActive, setFilterActive] = useState<boolean>(false);
    const [activeType, setActiveType] = useState<"active" | "inactive" | "all">(
        "active"
    );
    const [selectedCategory, setSelectedCategory] = useState<
        Category | undefined
    >(undefined);
    const [selectedSupplier, setSelectedSupplier] = useState<
        Supplier | undefined
    >(undefined);

    const [minCost, setMinCost] = useState<string | undefined>(undefined);
    const [maxCost, setMaxCost] = useState<string | undefined>(undefined);
    const [stickyBackroundHeader, setStickyBackroundHeader] =
        useState("transparent");
    const inputRef = useRef(null);
    const handleFilter = () => {
        setFilterActive(true);

        const filterObject: FilterValues = {
            category: selectedCategory,
            supplier: selectedSupplier,
            activeType: activeType,
            minCost: minCost ? parseFloat(minCost) : undefined,
            maxCost: maxCost ? parseFloat(maxCost) : undefined,
        };
        props.onFilter(filterObject);
    };

    const handleClearFilter = () => {
        props.clearFilter();
        setFilterActive(false);
        handleClearCategory();
        handleClearSupplier();
        setMinCost("");
        setMaxCost("");
        setActiveType("active");
        props.setActiveOnly(true);
    };

    const handleActiveType = (type: "active" | "inactive" | "all") => {
        setActiveType(type);
    };

    useEffect(() => {
        const handleScrollTwo = () => {
            if (inputRef.current) {
                if (window.scrollY > 34) {
                    setStickyBackroundHeader("#fff");
                } else {
                    setStickyBackroundHeader("transparent");
                }
            }
        };

        window.addEventListener("scroll", handleScrollTwo);
        return () => {
            window.removeEventListener("scroll", handleScrollTwo);
        };
    });
    useEffect(() => {
        if (filterOpen && (activeType === "inactive" || activeType === "all")) {
            props.setActiveOnly(false);
        } else if (filterOpen && activeType === "active") {
            props.setActiveOnly(true);
        }
    }, [filterOpen, activeType]);

    // Search functions
    const onCategoryItemClick = (id: number, name: string) => {
        const filterCategory = props.categories.find((obj) => obj.id === id);

        setSelectedCategory(filterCategory);
    };
    const onSupplierItemClick = (id: number, name: string) => {
        const filterSupplier = props.suppliers.find((obj) => obj.id === id);

        setSelectedSupplier(filterSupplier);
    };

    const handleClearCategory = () => {
        setSelectedCategory(undefined);
    };

    const handleClearSupplier = () => {
        setSelectedSupplier(undefined);
    };
    const handleCategorySearch = async (search: string) => {
        const searchFilter = props.categories
            .filter((obj) => {
                // Convert the object's name to lowercase for a case-insensitive comparison
                return obj.name.toLowerCase().includes(search);
            })
            .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
        ``;
        if (!searchFilter) {
            return;
        }
        return searchFilter.map((i) => {
            const parentCategory = props.categories.find(
                (c) => c.id === i.parentCategoryId
            );

            return {
                id: i.id,
                displayName: i.name,
                tags: "filter-category",
                parentName: parentCategory ? parentCategory.name : null,
            };
        });
    };

    const handleSupplierSearch = async (search: string) => {
        const searchFilter = props.suppliers.filter((obj) => {
            // Convert the object's name to lowercase for a case-insensitive comparison
            return obj.displayName.toLowerCase().includes(search) && obj.active;
        });
        if (!searchFilter) {
            return;
        }
        return searchFilter.map((i) => ({
            id: i.id,
            displayName: i.displayName,
            tags: i.displayName,
        }));
    };

    return (
        <Container
            style={{ backgroundColor: stickyBackroundHeader }}
            ref={inputRef}
        >
            <StyledHeader className={props.classNameProps}>
                {props.title}
                <div className="ml-2 w-30" style={{ fontSize: 14 }}>
                    <SearchSelect
                        activeOnly={props.activeOnly}
                        key={"itemSearch"}
                        searchType="header"
                        onItemClick={props.onItemClick}
                        setClientInfo={props.setClientInfo}
                        // @ts-ignore
                        onSearch={props.onSearch}
                        placeholder="Search..."
                        onKeywordCleared={() =>
                            props.onItemClick(-1, "", props.activeOnly)
                        }
                    />
                </div>
                <div className="ml-2 flex flex-row items-center">
                    <Button
                        className="border rounded-lg p-[0px 16px] h-[40px] w-[100px] text-[14px]"
                        onClick={() => setFilterOpen(true)}
                        uiTextColor="white"
                    >
                        Filter
                    </Button>
                    {filterActive && (
                        <button className="ml-2" onClick={handleClearFilter}>
                            <FiXCircle />
                        </button>
                    )}
                </div>
            </StyledHeader>
            <FilterModal
                uiActive={filterOpen}
                uiOnConfirm={() => {
                    handleFilter();
                    setFilterOpen(false);
                }}
                uiOnCloseFilter={() => {
                    setFilterOpen(false);
                    props.setActiveOnly(true);
                }}
                uiOnRequestClose={() => setFilterOpen(false)}
                uiCloseOnOutsideClick={true}
                uiCloseOnEsc={true}
            >
                <div className="mb-4">
                    <p className="mb-1">Category</p>
                    <div key="categoryFilterdiv">
                        <SearchSelect
                            key={"categoryFilter"}
                            searchType="modal"
                            searchPage="myshop"
                            onItemClick={onCategoryItemClick}
                            // @ts-ignore
                            onSearch={handleCategorySearch}
                            placeholder="Search..."
                            initialValue={
                                (selectedCategory && selectedCategory.name) ||
                                ""
                            }
                            onKeywordCleared={handleClearCategory}
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <p className="mb-1">Status</p>
                    <div className="flex flex-row">
                        <div className="flex flex-row items-center mr-2">
                            <CalculatorRadio
                                type="radio"
                                value={"active"}
                                checked={activeType === "active"}
                                className="cursor-pointer"
                                onChange={() => handleActiveType("active")}
                            />
                            Active
                        </div>
                        <div className="flex flex-row items-center mr-2">
                            <CalculatorRadio
                                type="radio"
                                value={"active"}
                                checked={activeType === "inactive"}
                                className="cursor-pointer"
                                onChange={() => handleActiveType("inactive")}
                            />
                            Inactive
                        </div>
                        <div className="flex flex-row items-center mr-2">
                            <CalculatorRadio
                                type="radio"
                                value={"active"}
                                checked={activeType === "all"}
                                className="cursor-pointer"
                                onChange={() => handleActiveType("all")}
                            />
                            All
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <p className="mb-1">Supplier</p>
                    <div key="supplierFilterdiv">
                        <SearchSelect
                            key={"supplierFilter"}
                            searchType="modal"
                            onItemClick={onSupplierItemClick}
                            // @ts-ignore
                            onSearch={handleSupplierSearch}
                            placeholder="Search..."
                            initialValue={
                                (selectedSupplier &&
                                    selectedSupplier.displayName) ||
                                ""
                            }
                            onKeywordCleared={handleClearSupplier}
                        />
                    </div>
                </div>
                <div>
                    <p className="mb-1">Price</p>
                    <div className="flex gap-2 pb-2">
                        <div className="flex flex-row items-center mr-2">
                            <CalculatorRadio
                                type="radio"
                                value={"active"}
                                checked={props.priceRadio === "retail"}
                                className="cursor-pointer"
                                onChange={() =>
                                    props.setPriceRadio &&
                                    props.setPriceRadio("retail")
                                }
                            />
                            Retail
                        </div>

                        <div className="flex flex-row items-center mr-2">
                            <CalculatorRadio
                                type="radio"
                                value={"active"}
                                checked={props.priceRadio === "trade"}
                                className="cursor-pointer"
                                onChange={() =>
                                    props.setPriceRadio &&
                                    props.setPriceRadio("trade")
                                }
                            />
                            Trade
                        </div>
                    </div>
                    <div className="flex flex-row w-full items-center">
                        <div className="relative w-full mr-2 ">
                            <PriceFilter
                                onChange={(e) => setMinCost(e.target.value)}
                                type={"number"}
                                className="border rounded-lg py-1 pl-5 w-full border-black/25"
                                value={minCost || ""}
                            />
                            <p className="absolute left-2 top-[4px] mb-0">$</p>
                        </div>
                        <p className="mb-0">to</p>{" "}
                        <div className="relative ml-2 w-full ">
                            <PriceFilter
                                onChange={(e) => setMaxCost(e.target.value)}
                                type={"number"}
                                className="border rounded-lg py-1 pl-5 w-full border-black/25"
                                value={maxCost || ""}
                            />
                            <p className="absolute left-2 top-[4px] mb-0">$</p>
                        </div>
                    </div>
                </div>
            </FilterModal>
            <Content>{props.children}</Content>
        </Container>
    );
});
