import { BaseAction } from "@quipa/api";
import { Dispatch } from "redux";
// import { loginByToken } from "../user";
import { RealtimeStatus } from "./model";
import { loginHandle } from "../../../api/signin";

export const SET_REALTIME_STATUS = "@@frontend/SET_REALTIME_STATUS";

export type SetRealtimeStatusAction = BaseAction<
    typeof SET_REALTIME_STATUS,
    RealtimeStatus
>;

/**
 * Set realtime application status
 * @param st New realtime status
 */
export function setRealtimeStatus(st: RealtimeStatus): SetRealtimeStatusAction {
    return {
        type: SET_REALTIME_STATUS,
        error: false,
        payload: st,
    };
}

// export const SET_APPLICATION_HEADER = "@@frontend/SET_APPLICATION_HEADER";

// export type SetApplicationHeaderAction = BaseAction<typeof SET_APPLICATION_HEADER, { label: string, icon: HeaderIconType }>;

// /**
//  * Set application header
//  * @param label Header label
//  * @param icon Header icon
//  */
// export function setApplicationHeader(label: string, icon: HeaderIconType): SetApplicationHeaderAction {
//     return {
//         type: SET_APPLICATION_HEADER,
//         error: false,
//         payload: {
//             label,
//             icon,
//         },
//     };
// }

export function initialAppSync(token: string) {
    return async (dispatch: Dispatch) => {
        const loginRes = await loginHandle({ token });
        // if (loginRes.error) {
        //     return loginRes;
        // }

        return loginRes;
    };
}

export interface ApplicationActions {
    // [SET_APPLICATION_HEADER]: SetApplicationHeaderAction;
    [SET_REALTIME_STATUS]: SetRealtimeStatusAction;
}
