import {
  CalculatorCheckbox,
  // CalculatorExtraDiv,
  CalculatorFieldInput,
  CalculatorFormField,
  CalculatorFormFieldHeader,
  CalculatorFormFieldLabel,
  CalculatorFormLabel,
  CalculatorFormQuestionNumber,
  CheckboxInputWrap,
  DraperyHeader,
  DrawingContainer,
  HContainer,
  NoteLetters,
  NotesContainer,
  // PrependAppendInput,
  // PrependAppendInputGroup,
  // PrependAppendInputGroupLabel,
  TooltipContainer,
  TooltipHeader,
  TooltipPopup,
  TooltipText,
} from "./styles";
import { DraperyModule } from "../../redux";
import {
  DraperyOrderDesignEmbellishment,
  DraperyOrderFabric,
  EmbellishmentCheckInfo,
  // highlightStyle,
} from "./calculator";
import React, { ChangeEvent, Component, Dispatch } from "react";
import AdditionalForm from "./additional-form";
import Drawing from "../UI/drawing/drawing";
import { TextArea } from "@ramble/ramble-ui";
import { ValidationErrorInfo } from "./interfaces-common";
import { duplicateFabric } from "./fabrics-tab";
import styled from "../../theme";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { FormFieldsAction } from "../../redux/modules/my-shop/form-fields-reducer";

export const viewBox = {
  x: 0,
  y: 20,
  width: 500,
  height: 200,
};

export interface DefaultRipplefoldOptions {
  controlType: string | undefined | null;
  controlLocation: string | undefined | null;
  masterCarrier: string | undefined | null;
  mount: string | undefined | null;
  headerTape: string | undefined | null;
}

export const defaultRipplefoldOptions = (): DefaultRipplefoldOptions => {
  return {
    controlType: null,
    controlLocation: null,
    masterCarrier: null,
    mount: null,
    headerTape: null,
  }
}

export const defaultWidthEachPanelList =
  (): DraperyModule.DraperyOrderDesignPanelJson => ({});

export const draperyLiningsList =
  (): DraperyModule.DraperyOrderDesignLining[] => [
    {
      id: 0,
      name: "Lining",
      active: false,
      description: "",
      draperyOrderDesignId: null,
    },
    {
      id: 0,
      name: "Interlining",
      active: false,
      description: "",
      draperyOrderDesignId: null,
    },
  ];

export const defaultEmbellishmentsList = (): EmbellishmentCheckInfo[] => [
  { checked: false, title: "Banding", stateName: "bandings" },
  { checked: false, title: "Cording", stateName: "cordings" },
  { checked: false, title: "Flange", stateName: "flanges" },
  { checked: false, title: "Trim", stateName: "trims" },
  { checked: false, title: "Other", stateName: "othersEmbellishments" },
];

export const defaultEmbellishment = (
  targetArray: string
): DraperyOrderDesignEmbellishment | undefined => {
  switch (targetArray) {
    case "bandings": {
      return defaultBanding();
    }
    case "cordings": {
      return defaultCording();
    }
    case "flanges": {
      return defaultFlange();
    }
    case "trims": {
      return defaultTrim();
    }
    case "othersEmbellishments": {
      return defaultOtherEmbellishment();
    }
  }
};

export const defaultBanding = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 1,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultCording = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 2,
  subType: undefined,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultFlange = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 3,
  subType: undefined,
  styleType: "Flat",
  leadReturnEdge: { active: false },
  topEdge: { active: false },
  bottomEdge: { active: false },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultTrim = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 4,
  subType: undefined,
  appliedType: undefined,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultOtherEmbellishment =
  (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 5,
    details: "",
    leadReturnEdge: {},
    topEdge: {},
    bottomEdge: {},
    draperyOrderDesignId: null,
    price: "",
    itemId: 0
  });

// duplicate
export const duplicateDraperyOrderOtherItemDesign = (
  draperyOrderDesign: DraperyModule.DraperyOrderDesign
): DraperyModule.DraperyOrderDesign => {
  return {
    ...draperyOrderDesign,
    id: 0,
    drawing: undefined,
  };
};

export const duplicateLining = (lining: any) => {
  return {
    ...lining,
    id: 0,
    draperyOrderDesignId: 0,
    draperyOrderLiningsFabric: {
      ...lining.draperyOrderLiningsFabric,
      id: 0,
    },
  };
};

export const duplicateEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment
) => {
  return {
    ...embellishment,
    id: 0,
    draperyOrderDesignId: 0,
    draperyOrderFabric: embellishment.draperyOrderFabric
      ? duplicateFabric(embellishment.draperyOrderFabric)
      : undefined,
  };
};

// validate
export const validateDraperyOrderDesign = (
  draperyOrderDesign: DraperyModule.DraperyOrderDesign,
  draperyItemDetail: any,
  embellishmentList: EmbellishmentCheckInfo[],
  liningsList: DraperyModule.DraperyOrderDesignLining[],
  mainFabric: DraperyOrderFabric,
  clearHighlightSection?: (name: string) => void
): ValidationErrorInfo[] => {
  const prefixName = "draperyOrderDesign.";
  let tabIndex = 1;
  let errors: ValidationErrorInfo[] = [];

  if (draperyOrderDesign.notes && draperyOrderDesign.notes.length > 280) {
    errors.push({
      tabIndex,
      name: prefixName + "notes",
      message: "Notes letters must be less than 280.",
    });
  }
  return errors;
};

export const validateLining = (
  index: number,
  lining: DraperyModule.DraperyOrderDesignLining
): ValidationErrorInfo[] => {
  const errors: ValidationErrorInfo[] = [];
  return errors;
};

const validateBandingLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of banding must be at least 1.",
    });
  }

  if (leadReturnEdge.size === undefined || leadReturnEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the banding width for the lead/return placement.",
    });
  } else if (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge } = embellishment;
  if (topEdge.size === undefined || topEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the banding width for the top placement.",
    });
  } else if (isNaN(+topEdge.size!) || topEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge } = embellishment;
  if (bottomEdge.size === undefined || bottomEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message:
        "Please specify the banding width for the bottom placement.",
    });
  } else if (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
) => {
  let errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge } = embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 1
  ).length;
  let embellishmentName = `Banding ${numEmbellishment > 1 ? index + 1 : ""}`;
  // banding validation
  if ((!orderType && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active) || (
    orderType && orderType !== 'custom-order' && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active
  )) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for banding placement.",
    });
  }
  if (leadReturnEdge.active) {
    errors = validateBandingLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (topEdge.active) {
    errors = validateBandingTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (bottomEdge.active) {
    errors = validateBandingBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const validateCordingEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
) => {
  const errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge, totalStrips, subType } =
    embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 2
  ).length;
  let embellishmentName = `Cording ${numEmbellishment > 1 ? index + 1 : ""}`;
  // cording validation
  if (subType === undefined) {
    errors.push({
      tabIndex,
      name: prefixName + "subType",
      message: "Please select the cording type.",
    });
  }
  if ((!orderType && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active) ||
    (orderType && orderType !== 'custom-order' && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for cording placement.",
    });
  }
  if (leadReturnEdge.active) {
    if (!totalStrips || totalStrips < 1) {
      errors.push({
        tabIndex,
        name: prefixName + "totalStrips",
        message:
          "Total number of vertical strips of cording must be at least 1.",
      });
    }
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const validateFlangeLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge, subType } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of flange must be at least 1.",
    });
  }
  if (
    (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the flange width for the lead/return placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge, subType } = embellishment;
  if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the flange width for the top placement.",
    });
  } else if (subType === 1 && (isNaN(+topEdge.size!) || topEdge.size! <= 0)) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge, subType } = embellishment;
  if (
    (bottomEdge.size === undefined || bottomEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message:
        "Please specify the flange width for the bottom placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
) => {
  let errors: ValidationErrorInfo[] = [];
  const index = Number(prefixName.split(".")[1]);
  const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 3
  ).length;
  let embellishmentName = `Flange ${numEmbellishment > 1 ? index + 1 : ""}`;
  // flange validation
  errors = checkUndefinedTypes(
    errors,
    tabIndex,
    prefixName,
    "subType",
    "Please select the flange type.",
    subType
  );

  if ((!orderType && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active) || (
    orderType && orderType !== 'custom-order' && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active
  )) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for flange placement.",
    });
  }
  if (leadReturnEdge.active) {
    errors = validateFlangeLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (topEdge.active) {
    errors = validateFlangeTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (bottomEdge.active) {
    errors = validateFlangeBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const checkUndefinedTypes = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  prefixName: string,
  suffix: string,
  message: string,
  typeParam?: number
) => {
  if (typeParam === undefined) {
    errors.push({
      tabIndex,
      name: prefixName + suffix,
      message,
    });
  }
  return errors;
};

const validateTrimLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge, subType } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of trim must be at least 1.",
    });
  }
  if (
    (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the trim width for the lead/return placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateTrimTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge, subType } = embellishment;
  if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the trim width for the top placement.",
    });
  } else if (subType === 1 && (isNaN(+topEdge.size!) || topEdge.size! <= 0)) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};
const validateName = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { name } = embellishment;
  if (!name) {
    errors.push({
      tabIndex,
      name: prefixName + "name",
      message: "Other Embellishement must be given a name.",
    });
  }
  return errors;
};

const validateUniqueName = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  unique: boolean,
  prefixName: string
) => {
  if (unique) {
    errors.push({
      tabIndex,
      name: prefixName + "name",
      message: "Other Embellishement name selected is already in use, please choose a different name.",
    });
  }
  return errors;
}

const validatePrice = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { price } = embellishment;
  if (!price) {
    errors.push({
      tabIndex,
      name: prefixName + "price",
      message: "Please specify the price for embbelishment.",
    });
  }
  return errors;
};
const validateOtherEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
  targetArrayIndex?: number
) => {
  let errors: ValidationErrorInfo[] = [];
  const foundSameName = list && list.length > 0 ? list.find((i, idx) => i.name === embellishment.name && idx !== targetArrayIndex) : false
  const { name, price } = embellishment;
  if (!name) {
    errors = validateName(errors, tabIndex, embellishment, prefixName);
  }
  if (foundSameName){
    errors = validateUniqueName(errors, tabIndex, foundSameName ? true : false, prefixName)
  }
  if (orderType && orderType !== 'custom-order' &&!price) {
    errors = validatePrice(errors, tabIndex, embellishment, prefixName);
  }

  return errors;
};

const validateTrimEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
) => {
  let errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 4
  ).length;
  let embellishmentName = `Trim ${numEmbellishment > 1 ? index + 1 : ""}`;
  // trim validation
  errors = checkUndefinedTypes(
    errors,
    tabIndex,
    prefixName,
    "subType",
    "Please select the trim type.",
    subType
  );

  if ((!orderType && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active) || (
    orderType && orderType !== 'custom-order' && !leadReturnEdge.active && !topEdge.active && !bottomEdge.active
  )) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for trim placement.",
    });
  }

  if (leadReturnEdge.active) {
    errors = validateTrimLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (topEdge.active) {
    errors = validateTrimTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (bottomEdge.active) {
    errors = validateTrimBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }
  return errors;
};

const validateTrimBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge, subType } = embellishment;
  if (
    (bottomEdge.size === undefined || bottomEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Please specify the trim width for the bottom placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};

export const validateEmbellishment = (
  targetArray: string,
  targetArrayIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
): ValidationErrorInfo[] => {
  const prefixName = `${targetArray}.${targetArrayIndex}.`;
  const tabIndex = 1;
  let errors: ValidationErrorInfo[] = [];
  const embelType = embellishment.type;

  switch (embelType) {
    case 1: {
      errors = validateBandingEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        orderType
      );
      break;
    }
    case 2: {
      errors = validateCordingEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        orderType
      );
      break;
    }
    case 3: {
      errors = validateFlangeEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        orderType
      );
      break;
    }
    case 4: {
      errors = validateTrimEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        orderType
      );
      break;
    }
    case 5: {
      errors = validateOtherEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        orderType,
        targetArrayIndex
      );
      break;
    }
    default:
      break;
  }

  return errors;
};

export const validateEmbellishmentList = (
  embellishmentsInfo: EmbellishmentCheckInfo,
  list: DraperyOrderDesignEmbellishment[],
  targetArray: string,
  orderType?: string,
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  if (embellishmentsInfo.checked) {
    for (const [index, embellishment] of list.entries()) {
      errors = errors.concat(
        validateEmbellishment(targetArray, index, embellishment, list, orderType)
      );
    }
  }
  return errors;
};

export const validateLiningsList = (
  list: DraperyModule.DraperyOrderDesignLining[],
  isNoneLinings?: boolean
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  let isSelected: boolean = false;
  for (const [index, lining] of list.entries()) {
    errors = errors.concat(validateLining(index, lining));
    isSelected = isSelected || lining.active;
  }
  if (!isNoneLinings && !isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneLinings",
      message: "Please select a lining option.",
    });
  }
  if (isNoneLinings && isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneLinings",
      message: "You cannot select a lining and none at the same time.",
    });
  }
  return errors;
};

export const validateEmbellishmentChecked = (
  list: EmbellishmentCheckInfo[],
  isNoneEmbellishment?: boolean
): ValidationErrorInfo[] => {
  const errors: ValidationErrorInfo[] = [];
  let isSelected: boolean = false;
  for (const embellishmentInfo of list) {
    isSelected = isSelected || embellishmentInfo.checked;
  }
  if (!isNoneEmbellishment && !isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneEmbellishment",
      message: "Please select a embellishment option.",
    });
  }
  if (isNoneEmbellishment && isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneEmbellishment",
      message:
        "You cannot select a embellishment and none at the same time.",
    });
  }
  return errors;
};

const LiningsWrap = styled.div`
    display: flex;
    align-items: flex-end;
`;

const AdditionalFormContainer = styled.div`
    border: 1px solid #bebebe;
    background: #fff;
    width: 100%;
    margin: 5px auto 0 auto;
    max-width: 900px;
    min-width: 570px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    > div:first-child {
        margin-top: 0;
    }

    > div {
        margin-top: 20px;
    }
`;

interface PanelConf {
  name: string;
  title: string;
  numberPanels: number;
  size: string;
}

interface PleatType {
  image: string;
  type: string;
  title: string;
  value: any;
  active: boolean;
  isDeleted?: boolean;
}

export const panelsConfiguration: PanelConf[] = [
  { name: "center", title: "SINGLE CENTER", numberPanels: 1, size: "small" },
  { name: "left", title: "LEFT", numberPanels: 1, size: "small" },
  { name: "right", title: "RIGHT", numberPanels: 1, size: "small" },
  { name: "pair", title: "PAIR", numberPanels: 2, size: "small" },
  {
    name: "three-panels",
    title: "3 PANELS",
    numberPanels: 3,
    size: "middle",
  },
  { name: "four-panels", title: "4 PANELS", numberPanels: 4, size: "long" },
  { name: "five-panels", title: "5 PANELS", numberPanels: 5, size: "long" },
];

export const pleatTypeList: PleatType[] = [
  {
    type: "rodandrings",
    title: "NONE (FLAT)",
    value: "NONE (FLAT)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "EURO (2 FINGER)",
    value: "EURO (2 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "EURO (3 FINGER)",
    value: "EURO (3 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "GOBLET",
    value: "GOBLET",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "INVERTED BOX",
    value: "INVERTED BOX",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "PINCH (2 FINGER)",
    value: "PINCH (2 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "PINCH (3 FINGER)",
    value: "PINCH (3 FINGER)",
    image: "",
    active: true,
  },
];

interface Props {
  draperyOrderDesign: DraperyModule.DraperyOrderDesign;
  liningsList: DraperyModule.DraperyOrderDesignLining[];
  isNoneLinings?: boolean;
  embellishmentsList: EmbellishmentCheckInfo[];
  isNoneEmbellishment?: boolean;
  isCustomizeConstructionOpen: boolean;
  disabled: boolean;
  highlightedSection: string | null | undefined;
  data?: any;
  workroomSetting: WorkroomSetting | null;
  formErrorID?: string;

  openImageModal(name: string, option: "add" | "delete"): void;
  resetPanelConf(
    val?: string | number | undefined,
    name?: string | undefined
  ): any;
  resetPleatType(): void;
  addNewLining(): void;
  addEmbellishment(targetArray: string): void;
  deleteEmbellishment(stateName: string, index: number): void;
  toggleCustomizeConstruction(): void;
  handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
  handleChangeRipplefoldOptions(e: ChangeEvent<HTMLInputElement>): void;
  handleRemoveRipplefoldDetail(key: string, value: string | undefined | null): void;
  handleInputChangeForNumberPrice(e: ChangeEvent<HTMLInputElement>): void;
  handleInputChangeForString(e: any): void;
  handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
  handleSelectListChange(value: string | number, name?: string): void;
  updateStateByName(
    name: string,
    value:
      | string
      | number
      | boolean
      | number[]
      | undefined
      | { otherPleatImageId?: string }
  ): void;
  onShapeChanged(shapes: DraperyModule.Shape[]): void;
  getEmbellishmentsByIndex(index: number): {
    name: string;
    list: DraperyOrderDesignEmbellishment[];
  };
  handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void;
  handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void;
  handleInputBlur?(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  draperyItemDetail?: any;
  dispatch: Dispatch<FormFieldsAction>;
  workOrderEditMode: boolean;
  handleRemoveDraperyDesignCategory(value: number): void;
  openConstructionDetailsEditModal?(): void;
  resetDraperyOrderEmbellishments(): void;
  resetLinings(): void;
  orderType?: string;
}

interface State {
  widthList: string[];
  dynamicPleatTypesAdded: boolean;
  pleatTypeListNew: PleatType[];
  isPleatModalOpen: boolean;
}

class OtherItemDesignTab extends Component<Props, State> {
  private drawingRef = React.createRef<Drawing>();

  public constructor(props: Props) {
    super(props);

    this.state = {
      widthList: [
        "0",
        "0.5",
        "1",
        "1.5",
        "2",
        "2.5",
        "3",
        "3.5",
        "4",
        "4.5",
        "5",
        "5.5",
        "6",
        "6.5",
        "7",
        "7.5",
        "8",
      ],
      dynamicPleatTypesAdded: false,
      pleatTypeListNew: [],
      isPleatModalOpen: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      data,
    } = this.props;

    const { draperyItemDetail } = this.props;
    let dynamicPleatTypes: PleatType[] = [];

    if (
      draperyItemDetail &&
      draperyItemDetail[0] &&
      draperyItemDetail[0].pleatTypesWithPricing &&
      !this.state.dynamicPleatTypesAdded
    ) {
      draperyItemDetail[0].pleatTypesWithPricing.forEach(
        (pleatType: {
          image: string;
          newPleatId: any;
          name: any;
          isActive: boolean;
          isDeleted: boolean;
        }) => {
          if (pleatType.newPleatId) {
            dynamicPleatTypes.push({
              type: "rodandrings",
              title: pleatType.name,
              value: pleatType.newPleatId,
              image: pleatType.image,
              active: pleatType.isActive,
              isDeleted: pleatType.isDeleted,
            });
          }
        }
      );

      this.setState(
        {
          dynamicPleatTypesAdded: true,
          pleatTypeListNew: [
            ...this.state.pleatTypeListNew,
            ...pleatTypeList,
            ...dynamicPleatTypes,
          ],
        },
        () => { }
      );
    }
    if (data.draperyOrderDesign.headingType === "ripplefold" && data.draperyOrderDesign.pleatType !== "") {
      data.draperyOrderDesign.pleatType = "";
    }
  }

  public drawingBlob = async (): Promise<Blob | null> => {
    if (this.drawingRef.current) {
      return this.drawingRef.current.convertToImage();
    } else {
      return null;
    }
  };

  public render() {
    return (
      <>
        {/* <DraperyHeader>TREATMENT FUNCTION</DraperyHeader> */}
        {this.renderFirstQuestion()}
        {this.renderSecondQuestion()}
        {this.renderDrawingSection()}

        {/* <DraperyHeader>STYLE</DraperyHeader> */}

        {/* <DraperyHeader>LININGS</DraperyHeader> */}

        {/* <DraperyHeader>EMBELLISHMENTS & ADD-ONS</DraperyHeader> */}
        {this.renderEighthQuestion()}

        <DraperyHeader>NOTES</DraperyHeader>
        {this.renderNotesSection()}
      </>
    );
  }

  private renderFirstQuestion = (): JSX.Element => {
    const { formErrorID, draperyOrderDesign, highlightedSection } =
      this.props;
    return (
      <>
        <CalculatorFormField
          id="1"
          uiInline
          uiNoControlWrap
          uiNoMargin
          isError={
            highlightedSection === "draperyOrderDesign.category"
          }
        >
          <CalculatorFormFieldHeader style={{ marginTop: '16px' }}>
            <CalculatorFormQuestionNumber
              uiError={formErrorID === "1"}
            >
              1
            </CalculatorFormQuestionNumber>
            <CalculatorFormFieldLabel>
              Description
            </CalculatorFormFieldLabel>
            <TooltipPopup
              text={
                <TooltipContainer>
                  <TooltipText>
                    Description, functionality, dimensions, mount, etc.
                  </TooltipText>
                </TooltipContainer>
              }
              position="top left"
              className="normal"
            />
          </CalculatorFormFieldHeader>

          <TextArea
            className="description"
            style={{
              border: "1px solid #B3B3B3",
              height: "170px",
              marginTop: '16px',
            }}
            onKeyDown={(e) => this.handleEnterPressed(e)}
            placeholder="Enter description"
            name="draperyOrderDesign.description"
            onChange={this.props.handleInputChangeForString}
            value={draperyOrderDesign.description}
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          />
        </CalculatorFormField>
      </>
    );
  };

  private handleEnterPressed(
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ): void {
    const key = e.key;
    if (key === "Enter" || key === "Return") {
      e.preventDefault();

      const textarea = e.currentTarget;
      const value = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;

      const part1 = value.slice(0, selectionStart);
      const part2 = value.slice(selectionEnd);

      const newValue = part1 + "\n" + part2;

      textarea.value = newValue;
      textarea.selectionStart = textarea.selectionEnd =
        selectionStart + 1;
    }
  };

  private renderSecondQuestion = (): JSX.Element => {
    const { formErrorID, liningsList, highlightedSection } = this.props;

    const isLiningActive = liningsList.some((lining) => lining.active);
    const isNoneLinings = this.props.isNoneLinings;

    return (
      <CalculatorFormField
        id="2"
        uiInline
        uiNoMargin
        isError={highlightedSection === "isNoneLinings"}
      >
        <CalculatorFormFieldHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "2"}>
            2
          </CalculatorFormQuestionNumber>
          <CalculatorFormFieldLabel>
            Linings
          </CalculatorFormFieldLabel>
        </CalculatorFormFieldHeader>
        <LiningsWrap>
          <CheckboxInputWrap
            className="flex-row"
            style={{ marginTop: '16px' }}
          >
            <CalculatorFieldInput>
              <CalculatorFormLabel>
                <CalculatorCheckbox
                  id="isNoneLinings"
                  name="isNoneLinings"
                  checked={isNoneLinings}
                  onChange={(e) => {
                    this.props.handleInputChangeForBoolean(e)
                    this.props.resetLinings()
                  }}
                  disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                  uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                />
                None
              </CalculatorFormLabel>
            </CalculatorFieldInput>
            {liningsList.map((el, i) => {
              return (
                <CalculatorFieldInput key={el.name}>
                  <CalculatorFormLabel>
                    <CalculatorCheckbox
                      id={"liningsList." + i + ".active"}
                      name={
                        "liningsList." + i + ".active"
                      }
                      checked={
                        el.active && isLiningActive
                      }
                      onChange={
                        this.props
                          .handleInputChangeForBoolean
                      }
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    {el.name}
                  </CalculatorFormLabel>
                </CalculatorFieldInput>
              );
            })}
          </CheckboxInputWrap>
        </LiningsWrap>
      </CalculatorFormField>
    );
  };

  private renderEighthQuestion = (): JSX.Element => {
    const {
      formErrorID,
      embellishmentsList,
      isNoneEmbellishment,
      highlightedSection,
    } = this.props;

    const isEmbellishmentActive = embellishmentsList.some(
      (embellishment) => embellishment.checked
    );

    return (
      <CalculatorFormField
        id="3"
        uiInline
        uiNoMargin
        isError={highlightedSection === "isNoneEmbellishment"}
      >
        <CalculatorFormFieldHeader style={{ marginTop: '16px' }}>
          <CalculatorFormQuestionNumber uiError={formErrorID === "3"}>
            3
          </CalculatorFormQuestionNumber>
          <CalculatorFormFieldLabel>
            Embellishments
          </CalculatorFormFieldLabel>
          <TooltipPopup
            text={
              <TooltipContainer>
                <TooltipHeader>BANDING</TooltipHeader>
                <TooltipText>
                  Fabric (usually a contrast fabric) seamed to
                  the main fabric around the border or inset
                  within the drapery.
                </TooltipText>
                <TooltipHeader>Flange</TooltipHeader>
                <TooltipText>
                  A strip of fabric that is folded in half and
                  sewn into the edge of the drapery.
                </TooltipText>
                <TooltipHeader>Trim</TooltipHeader>
                <TooltipText>
                  Strips of fabric or pre-made trim applied on
                  top of the main fabric.
                </TooltipText>
                <TooltipHeader>Cording</TooltipHeader>
                <TooltipText>
                  Rope that is sewn into a seam or edge. This
                  can be a pre-made decorative cording or welt
                  cording (non-decorative cording covered with
                  fabric).
                </TooltipText>
              </TooltipContainer>
            }
            position="top left"
            className="floatRight"
            size="500"
          />
        </CalculatorFormFieldHeader>
        <HContainer
          style={{ padding: "16px 0px 0px 0px" }}
          className="flex-col lg:flex-row items-start lg:items-center"
        >
          <CalculatorFormLabel key={-1}>
            <CalculatorCheckbox
              id="isNoneEmbellishment"
              name="isNoneEmbellishment"
              checked={isNoneEmbellishment}
              onChange={(e) => {
                this.props.handleInputChangeForBoolean(e)
                this.props.resetDraperyOrderEmbellishments()
              }}
              disabled={this.props.workOrderEditMode ? false : this.props.disabled}
              uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
            />
            None
          </CalculatorFormLabel>
          {embellishmentsList.map((el, i) => (
            <CalculatorFormLabel key={i}>
              <CalculatorCheckbox
                id={`embellishmentsList.${i}.checked`}
                name={`embellishmentsList.${i}.checked`}
                checked={el.checked && isEmbellishmentActive}
                onChange={
                  this.props.handleInputChangeForBoolean
                }
                disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
              />
              {el.title}
            </CalculatorFormLabel>
          ))}
        </HContainer>
        {embellishmentsList.some((el) => el.checked === true) && (
          <AdditionalFormContainer>
            {embellishmentsList.map(this.renderEmbellishments)}
          </AdditionalFormContainer>
        )}
      </CalculatorFormField>
    );
  };

  private renderEmbellishments = (
    embellishmentInfo: EmbellishmentCheckInfo,
    index: number
  ) => {
    const { list, name } = this.props.getEmbellishmentsByIndex(index);
    if (!embellishmentInfo.checked || !list.length) {
      return <></>;
    }
    return list.map((e, i) => this.renderEmbellishment(e, i, name, list));
  };

  private renderEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    index: number,
    targetArray: string,
    list: DraperyOrderDesignEmbellishment[]
  ) => {
    return (
      <AdditionalForm
        key={targetArray + index}
        targetArray={targetArray}
        targetArrayIndex={index}
        embellishment={embellishment}
        addEmbellishment={this.props.addEmbellishment}
        deleteEmbellishment={this.props.deleteEmbellishment}
        differentiate={list.length > 1 && index + 1}
        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
        handleInputChangeForNumber={
          this.props.handleInputChangeForNumber
        }
        handleInputChangeForString={
          this.props.handleInputChangeForString
        }
        handleInputChangeForBoolean={
          this.props.handleInputChangeForBoolean
        }
        handleInputChangeForNumberPrice={
          this.props.handleInputChangeForNumberPrice
        }
        handleInputBlur={this.props.handleInputBlur}
        handleSelectListChange={this.props.handleSelectListChange}
        updateStateByName={this.props.updateStateByName}
        highlightedSection={this.props.highlightedSection}
        orderType={this.props.orderType}
      />
    );
  };

  private renderDrawingSection = (): JSX.Element => {
    return (
      <CalculatorFormField
        id="drawing-section"
        uiInline
        uiNoMargin
        noPadding
      >
        <DrawingContainer className={this.props.orderType === 'custom-order' ? 'left' : ''}>
          <Drawing
            shapes={this.props.draperyOrderDesign.drawingShapeJson}
            category={this.props.draperyOrderDesign.category}
            panelType={this.props.draperyOrderDesign.panelType}
            panelJson={this.props.draperyOrderDesign.panelJson}
            rodWidth={this.props.draperyOrderDesign.rodWidth}
            finishedLengthOfPanels={
              this.props.draperyOrderDesign.finishedLengthOfPanels
            }
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            onShapeChanged={this.props.onShapeChanged}
            ref={this.drawingRef}
            orderType={this.props.orderType}
          />
        </DrawingContainer>
      </CalculatorFormField>
    );
  };

  private renderNotesSection = (): JSX.Element => {
    const { draperyOrderDesign } = this.props;
    const leftLetters = 280 - (draperyOrderDesign.notes || "").length;
    return (
      <CalculatorFormField id="notes-section" uiInline uiNoMargin>
        <NotesContainer>
          <TextArea
            style={{
              border: "1px solid #B3B3B3",
              width: "76%",
              height: "211px",
            }}
            name="draperyOrderDesign.notes"
            onChange={this.props.handleInputChangeForString}
            value={draperyOrderDesign.notes}
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          />
          <NoteLetters>{leftLetters} letters left</NoteLetters>
        </NotesContainer>
      </CalculatorFormField>
    );
  };
}

export default OtherItemDesignTab;
