import React, { FC, memo, CSSProperties } from "react";
import styled, { theme } from "../../theme";

const SectionContainer = styled.div`
    flex: 1 1 auto;
    background-color: ${theme.colors.contentBackground};
    margin: 0;
    // padding: 10px;
    // box-shadow: 2px 2px 15px 0 #ddd;
`;
interface ContentSectionProps {
    style?: CSSProperties; 
}
/**
 * Page Content
 */
export const ContentSection: FC<ContentSectionProps> = memo(({ style, children }) => {
    return (
        <SectionContainer style={style}>
            {children}
        </SectionContainer>
    );
});
