import React, { ChangeEvent, Component } from "react";

import { DraperyOrderDesignLining } from "./calculator";
import FabricsLiningsComponent from "./fabrics-tab-linings-component";
import { SearchSelectProps } from "../../components/UI/search_select/search_select";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { DraperyModule } from "../../redux";

export interface Props {
    liningsList: DraperyOrderDesignLining[];
    disabled: boolean;
    liningsPatternItems: any;
    workroomSetting?: WorkroomSetting;
    orderOriginState?: number;
    onSupplierSearch: SearchSelectProps["onSearch"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;
    openFabricsPatternModalClick(pathId: string, pathName: string): void;
    onFabricsLiningPatternSearch: SearchSelectProps["onSearch"];
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string
    ): void;
    handleSearchSelectClear(
        pathId: string,
        pathName: string,
        key?: number
    ): void;
    highlightedSection: string | null | undefined;
    openEditFabricsPatternModalClick(pathName: string, itemId?: number | null): void;
    workOrderEditMode: boolean;
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    isCopy: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface FabricState { }

class FabricsLiningsSection extends Component<Props, FabricState> {
    componentWillReceiveProps(
        //ovde.......
        nextProps: Readonly<Props>,
        nextContext: any
    ): void {
        if (
            nextProps.liningsList[0] && nextProps.liningsList[0].draperyOrderLiningsFabric &&
            nextProps.liningsList[0].draperyOrderLiningsFabric.orderOrigin !==
            1 &&
            nextProps.liningsList[0].draperyOrderLiningsFabric &&
            nextProps.liningsList[0].draperyOrderLiningsFabric.orderOrigin !== 2
        ) {
            if (
                nextProps.liningsList[0].active &&
                nextProps.orderOriginState &&
                this.props.liningsList[0].draperyOrderLiningsFabric
            ) {
                this.props.liningsList[0].draperyOrderLiningsFabric.orderOrigin =
                    nextProps.orderOriginState;
            }
        }
        if (
            nextProps.liningsList[1] && nextProps.liningsList[1].draperyOrderLiningsFabric &&
            nextProps.liningsList[1].draperyOrderLiningsFabric.orderOrigin !==
            1 &&
            nextProps.liningsList[1].draperyOrderLiningsFabric &&
            nextProps.liningsList[1].draperyOrderLiningsFabric.orderOrigin !== 2
        ) {
            if (
                nextProps.liningsList[1].active &&
                nextProps.orderOriginState &&
                this.props.liningsList[1].draperyOrderLiningsFabric
            ) {
                this.props.liningsList[1].draperyOrderLiningsFabric.orderOrigin =
                    nextProps.orderOriginState;
            }
        }
    }
    public render() {
        const { liningsList, liningsPatternItems, workroomSetting, draperyOrder, isCopy } =
            this.props;
        if (!liningsList || !liningsList.length) return null;

        return liningsList.map((lining, index) => {
            const { name } = lining;

            if (!liningsList[index].active) return;

            return (
                <FabricsLiningsComponent
                    key={`${name}-${index}`}
                    disabled={this.props.disabled}
                    draperyOrderDesignLining={lining}
                    targetArray="liningsList"
                    targetArrayIndex={index}
                    type={20}
                    openFabricsPatternModalClick={
                        this.props.openFabricsPatternModalClick
                    }
                    onFabricsLiningPatternSearch={
                        this.props.onFabricsLiningPatternSearch
                    }
                    onSupplierSearch={this.props.onSupplierSearch}
                    openSupplierModalClick={this.props.openSupplierModalClick}
                    openImageModal={this.props.openImageModal}
                    handleInputChangeForNumber={
                        this.props.handleInputChangeForNumber
                    }
                    handleInputChangeForString={
                        this.props.handleInputChangeForString
                    }
                    handleInputChangeForBoolean={
                        this.props.handleInputChangeForBoolean
                    }
                    handleSelectListChange={this.props.handleSelectListChange}
                    handleSearchSelectChange={
                        this.props.handleSearchSelectChange
                    }
                    handleSearchSelectClear={this.props.handleSearchSelectClear}
                    highlightedSection={this.props.highlightedSection}
                    liningsPatternItems={liningsPatternItems}
                    workroomSetting={workroomSetting}
                    openEditFabricsPatternModalClick={this.props.openEditFabricsPatternModalClick}
                    workOrderEditMode={this.props.workOrderEditMode}
                    draperyOrder={draperyOrder}
                    isCopy={isCopy}
                />
            );
        });
    }
}

export default FabricsLiningsSection;
