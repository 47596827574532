import { combineReducers, MiddlewareAPI, Store } from "redux";
import * as AccountModule from "./account";
import accountReducer from "./account/reducer";
import * as AdminModule from "./admin";
import * as ApplicationModule from "./application";
import appReducer from "./application/reducer";
import * as ClientModule from "./client";
import clientReducer from "./client/reducer";
import * as ClientDashboardModule from "./client_dashboard";
import * as DraperyModule from "./drapery-planning";
import draperyReducer from "./drapery-planning/reducer";
import * as DraperyOrderPriceModule from "./drapery_order_price";
import * as NotificationsModule from "./notifications";
import notificationReducer from "./notifications/reducer";
import * as RoomModule from "./room";
import * as SuppliersModule from "./supplier";
import * as UserModule from "./user";
import userReducer from "./user/reducer";
import * as WorkOrderModule from "./work_order";
import * as WorkroomSettingModule from "./workroom_setting";
// import adminReducer from "./admin/reducer";

/**
 * Combine all reducers
 */
export default combineReducers<RootState>({
    application: appReducer,
    user: userReducer,
    account: accountReducer,
    client: clientReducer,
    // admin: adminReducer,
    draperyOrder: draperyReducer,
    notifications: notificationReducer,
});

/**
 * General root state
 */
export interface RootState {
    /**
     * Application state options
     */
    application: ApplicationModule.State;
    /**
     * Application current user state
     */
    user: UserModule.State;
    /**
     * User account(team) state
     */
    account: AccountModule.State;
    /**
     * Client state
     */
    client: ClientModule.State;
    /**
     * Drapery Order state
     */
    draperyOrder: DraperyModule.State;
    /**
     * Notifications state
     */
    notifications: NotificationsModule.State;
}

/**
 * Application store
 */
export interface ApplicationStore extends Store<RootState> {}
/**
 * Application middleware store
 */
export interface ApplicationMiddlewareStore
    extends MiddlewareAPI<any, RootState> {}

// export type RealtimeActions = PortalApi.RealtimeActions;

export {
    ApplicationModule,
    UserModule,
    AccountModule,
    ClientModule,
    SuppliersModule,
    AdminModule,
    DraperyModule,
    ClientDashboardModule,
    RoomModule,
    WorkOrderModule,
    WorkroomSettingModule,
    NotificationsModule,
    DraperyOrderPriceModule,
};

if (module.hot) {
    module.hot.accept("./index", () => {
        /* Ignore */
    });
}
