import { ActionDescriptor } from "../../common";

export const UPDATE_USER = "user/UPDATE";

export type UpdateUserAction = ActionDescriptor<
    typeof UPDATE_USER,
    {
        /**
         * First Name
         */
        firstName?: string;
        /**
         * user
         */
        user?: any;
        /**
         * Last name
         */
        lastName?: string;
        /**
         * Email
         */
        email?: string;
        logoId?: string | null;
    },
    {}
>;

export const UPDATE_USER_PASSWORD = "user/UPDATE_PASSWORD";

export type UpdateUserPasswordAction = ActionDescriptor<
    typeof UPDATE_USER_PASSWORD,
    {
        /**
         * Old password
         */
        oldPassword: string;
        /**
         * New password
         */
        password: string;
    },
    {}
>;

export const GET_BY_EMAIL = "user/GET_BY_EMAIL";

export type GetByEmailAction = ActionDescriptor<
    typeof GET_BY_EMAIL,
    {
        email: string;
    },
    {}
>;

export const UPLOAD_PROFILE_IMAGE = "user/UPLOAD_PROFILE_IMAGE";
export type UploadProfileImage = ActionDescriptor<
    typeof UPLOAD_PROFILE_IMAGE,
    {
        /**
         * Account id
         */
        accountId: number;
        /**
         * Image blob/file
         */
        data: Blob;
    },
    {
        /**
         * Image url
         */
        url: string;
    }
>;

export interface UserActionsMap {
    [UPDATE_USER]: UpdateUserAction;
    [UPDATE_USER_PASSWORD]: UpdateUserPasswordAction;
    [GET_BY_EMAIL]: GetByEmailAction;
    [UPLOAD_PROFILE_IMAGE]: UploadProfileImage;
}
